.ag-grid-search {
  padding: 0 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .input-wrapper {
    display: flex;
    flex-grow: 1;
    position: relative;
    input {
      @extend .form-control;
      padding-left: 30px;
    }

    &::after {
      content: '\f002';
      font-family: 'FontAwesome5Free';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
}

.ag-grid-with-search {
  .table-wrapper {
    display: flex;
    flex-direction: row;
    height: calc(100% - 55px);

    > div {
      position: relative;
      height: 100%;
    }

    .ag-grid-details {
      display: flex;
      flex-grow: 1;
      padding: 0 10px;
    }
  }
}
