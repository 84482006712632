.panel {
  position: relative;
  border-radius: 0;

  .panel-heading {
    border-radius: 0;
  }
}

.panel-heading {
  height: 40px;

  h2 {
    float: left;
    margin: 0;

    &.title {
      text-align: center;
      line-height: 33px;
      font-size: 18px;
    }

    &.block {
      float: none;
    }
  }

  .panel-toolbar {
    display: inline-block;
    width: auto;
    height: 38px;
    line-height: 37px;
    position: relative;
    border-left: 1px solid $panel-default-border;
    cursor: pointer;
    padding: 0 5px;
    float: left;

    > .btn-group {
      margin-top: -1px;
    }

    > .btn,
    .btn-group > .btn,
    .toolbar-btn {
      padding: 3px 8px 3px !important;
    }

    input[type='text'],
    .toolbar-input {
      margin-top: 4px;
      color: #999;
    }

    .row > div {
      > .input-group {
        margin-top: 3px;
      }

      > .toolbar-btn {
        margin-top: 6px;
      }
    }

    .input-group-xs {
      line-height: 24px;

      input {
        height: 22px !important;
      }
    }

    .select2-container {
      margin-top: 4px;
    }
  }

  .datetimepicker {
    width: 100%;
    margin: 3px 0 0 0;
    border: 1px solid #ddd;
    height: 25px !important;
    padding: 2px 6px;
    line-height: 24px;
    color: #999;
  }

  .panel-toolbars.pull-right {
    margin-right: -10px;
  }

  .nav-tabs {
    .panel-toolbars.pull-right {
      margin-right: 0;
    }
  }

  .panel-toolbars.pull-left {
    margin-left: -10px;

    .panel-toolbar:first-child {
      border-left: 0;
    }
  }

  &,
  h2 {
    font-size: 14px;
    font-weight: normal;
    line-height: 38px;

    .dropdown-menu {
      line-height: normal;
    }
  }
}

.panel.tabs-overflow {
  .panel-heading {
    overflow-x: auto;
    overflow-y: hidden;

    .nav-tabs {
      white-space: nowrap;
      > li {
        float: none;
        display: inline-block;
      }
    }
  }
}

.panel.panel-lg {
  border-radius: 5px;

  .panel-heading {
    height: 70px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 0 15px;

    .panel-toolbar,
    * > .panel-toolbar {
      display: inline-block;
      width: auto;
      height: 33px;
      line-height: 31px;
      position: relative;
      border-left: 1px solid $panel-default-border;
      cursor: pointer;
      padding: 0 5px;
      float: right;

      > .btn-group {
        margin-top: -1px;
      }

      > .btn,
      .btn-group > .btn,
      .toolbar-btn {
        padding: 3px 8px 3px !important;
      }

      input[type='text'],
      .toolbar-input {
        height: 28px !important;
        margin-top: 2px;
        color: #999;
      }

      .input-group-xs {
        line-height: 33px;
      }

      .select2-container {
        margin-top: 4px;
      }
    }

    &,
    h2 {
      font-size: 14px;
      line-height: 68px;
      font-weight: bold;
      text-transform: uppercase;
    }

    h2 {
      small {
        color: darken($headings-small-color, 40%);
      }
    }
  }

  .panel-body {
    padding: 15px;
  }

  .panel-footer {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px 15px;
  }
}

.panel.panel-white {
  .panel-heading {
    background-color: #fff;
  }
}

.panel-sign-in {
  > .panel-heading {
    height: auto;
    padding: 20px;

    .logo {
      width: 74px;
      height: 74px;
      margin: auto;
      padding: 11px;
      border-radius: 50%;
      background: #58b032;

      img {
        height: 50px;
        margin: auto;
        display: block;
      }
    }
  }

  > .panel-body {
    padding: 20px;
  }
}

.panel-group {
  a {
    outline: none;
  }
}

.panel.panel-inverse {
  &,
  .panel-heading {
    border-color: $navbar-inverse-border;
  }

  & > .panel-heading {
    background: $navbar-inverse-bg;
    color: #fff;

    .panel-toolbar {
      border-left: 1px solid darken($navbar-inverse-bg, 15%);
    }
  }
}

.absolute > .panel,
.panel.absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;

  > .panel-body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
  }

  > .panel-heading + .panel-body {
    top: 40px;
  }
}

.panel-bold {
  border-width: 2px;
}

.panel-dashboard {
  @extend .panel-default;

  .panel-heading {
    @extend .nowrap;
    height: 50px;
    padding: 0 10px;

    &,
    h2 {
      font-size: 14px;
      line-height: 48px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .panel-toolbars {
    }

    .panel-toolbar {
      height: 49px;
      line-height: 48px;
      padding: 0 8px;
    }
  }

  .panel-body {
    padding: 10px;
  }

  .panel-footer {
    padding: 5px 10px;
  }

  &.absolute {
    .panel-heading + .panel-body {
      top: 50px;
    }
  }
}

@media (max-width: $screen-ms-max) {
  .panel-heading {
    height: auto;

    h2 {
      float: none;
    }
  }
}
