.event-state {
  &.label {
    display: inline-block;
    width: 85px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid transparent;

    &.label-lg {
      width: 120px;
      font-size: 100%;
    }

    &.OPENED {
      background: $btn-danger-bg;
      border-color: $btn-danger-border;
      color: $btn-danger-color;
    }

    &.CLOSED {
      background: $btn-default-bg;
      color: $btn-default-color;
      border: 1px solid $btn-default-border;
    }

    &.RESOLVED {
      background: $btn-success-bg;
      border-color: $btn-success-border;
      color: $btn-success-color;
    }

    &.IN_PROGRESS {
      background: $btn-info-bg;
      border-color: $btn-info-border;
      color: $btn-info-color;
    }

    &.PENDING {
      background: $btn-warning-bg;
      border-color: $btn-warning-border;
      color: $btn-warning-color;
    }

    &.IGNORED {
      background: $btn-primary-bg;
      border-color: $btn-primary-border;
      color: $btn-primary-color;
    }
  }
}

.event-state-select {
  .select2-container .select2-choice {
    height: auto;
    line-height: 1.3;
    border: none !important;
    background: transparent;
    padding-left: 0;

    .select2-arrow {
      width: 20px;
      background: transparent;
      border: none;
    }
  }

  .select2-results {
    padding: 0;
    margin-right: 0;

    .select2-highlighted {
      background: transparent;
    }
  }

  .ui-select-choices-row:hover {
    background: #f5f5f5 !important;
  }
}

event-dashboard-table,
event-dashboard-table-ag,
edge-event-table {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .events-table {
    tr {
      cursor: pointer;
    }
  }
}

event-comment-display,
edge-event-comment-display {
  p {
    position: relative;
    min-height: 51px;
  }

  .comment-edited {
    text-align: right;
    font-size: 11px;
    color: $gray;
    margin-top: 5px;
  }

  .comment-editor-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .comment-editor {
      border-radius: 0;
      box-shadow: none !important;
      padding: 2px;
    }
  }

  .comment-actions {
    position: absolute;
    top: 0;
    right: 0;

    .close {
      opacity: 1;

      &:focus {
        outline: none;
      }
    }
  }
}
