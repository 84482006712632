.scada {
  background: $gray-lightest;
  #draw-container {
    @extend .absolute;
    overflow: auto;
  }

  #draw {
    width: 6000px;
    height: 6000px;
    background: #fff;
  }

  rect {
    &.mlabel {
      fill: #999999;

      &.class609450 {
        fill: #739e73;
      }
      &.classfbd75b {
        fill: #c79121;
      }
      &.classdc2127 {
        fill: #a90329;
      }
    }
  }

  text {
    &.mlabel {
      fill: #f0f0f0;

      &.class609450 {
        fill: #ffffff;
      }
      &.classfbd75b {
        fill: #ffffff;
      }
      &.classdc2127 {
        fill: #ffffff;
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  .scada {
    #draw-container {
      &::-webkit-scrollbar:vertical {
        width: 20px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 20px;
        width: 40px;
      }

    }
  }
}
