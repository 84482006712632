.kpi-search-bar {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.kpi-search-bar-icon {
  color: #aaa; padding-left: 10px; position: absolute; z-index: 1
}

.kpi-search-bar-input {
  padding-left: 30px; position: relative; z-index: 0
}

.variable-form{
  .popover-content{     color: #5b7179  ; }
  .popover-box{
    color: white;
    font-size: 15px;
    padding-right: 5px;
  }
}