.toggle-switch {
  margin: 0;
  font-size: 1.2em;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  width: 1.6em;
  height: 0.6em;
  background: lighten($input-bg, 5%);

  input[type='checkbox'] {
    display: none;
  }

  &:after {
    content: '';
    background: $gray-dark;
    position: absolute;
    top: -0.2em;
    //left: -2px;
    right: calc(100% - 0.9em);
    border-radius: 50%;
    border: 1px solid $gray;
    width: 1em;
    height: 1em;
    transition: right 0.2s linear;
  }

  &.active {
    &:after {
      left: auto;
      right: -0.1em;
      background: $btn-primary-bg;
      border-color: $btn-primary-border;
    }
  }

  &.indeterminate {
    &:after {
      left: 50%;
      transform: translateX(-50%);
      background: $btn-warning-bg;
      border-color: $btn-warning-border;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  &.toggle-switch-lg {
    width: 2em;

    &:after {
      top: -0.3em;
      width: 1.3em;
      height: 1.3em;
      right: calc(100% - 1.1em);
    }

    &.active {
      &:after {
        right: -0.25em;
      }
    }
  }
}
