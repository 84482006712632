.retail-tracking {
  .tracking-bg-container {
    position: relative;

    > * {
      margin: auto;
    }

    .background {
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }

  .count-item {
    padding: 10px;
    border: 1px solid darken($border-color, 5%);
    margin-bottom: 5px;

    hr {
      margin-top: 3px;
      margin-bottom: 8px;
    }

    &.hovered {
      background: lighten(#ff4a4a, 10%);
      color: darken($text-color, 20%);
      border-color: darken(#ff4a4a, 15%);

      hr {
        border-color: darken(#ff4a4a, 15%);
      }
    }

    .fas-minus {
      color: #6CBC00
    }

    .fas-square {
      color: #c17f3e
    }
  }
}
