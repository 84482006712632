@charset "UTF-8";

td.dt-control {
  cursor: pointer;
}

table.dataTable {
  th.dt-left,
  td.dt-left {
    text-align: left;
  }

  th.dt-center {
    text-align: center;
  }

  td {
    &.dt-center,
    &.dataTables_empty {
      text-align: center;
    }
  }

  th.dt-right,
  td.dt-right {
    text-align: right;
  }

  th.dt-justify,
  td.dt-justify {
    text-align: justify;
  }

  th.dt-nowrap,
  td.dt-nowrap {
    white-space: nowrap;
  }

  thead {
    th.dt-head-left,
    td.dt-head-left {
      text-align: left;
    }
  }

  tfoot {
    th.dt-head-left,
    td.dt-head-left {
      text-align: left;
    }
  }

  thead {
    th.dt-head-center,
    td.dt-head-center {
      text-align: center;
    }
  }

  tfoot {
    th.dt-head-center,
    td.dt-head-center {
      text-align: center;
    }
  }

  thead {
    th.dt-head-right,
    td.dt-head-right {
      text-align: right;
    }
  }

  tfoot {
    th.dt-head-right,
    td.dt-head-right {
      text-align: right;
    }
  }

  thead {
    th.dt-head-justify,
    td.dt-head-justify {
      text-align: justify;
    }
  }

  tfoot {
    th.dt-head-justify,
    td.dt-head-justify {
      text-align: justify;
    }
  }

  thead {
    th.dt-head-nowrap,
    td.dt-head-nowrap {
      white-space: nowrap;
    }
  }

  tfoot {
    th.dt-head-nowrap,
    td.dt-head-nowrap {
      white-space: nowrap;
    }
  }

  tbody {
    th.dt-body-left,
    td.dt-body-left {
      text-align: left;
    }

    th.dt-body-center,
    td.dt-body-center {
      text-align: center;
    }

    th.dt-body-right,
    td.dt-body-right {
      text-align: right;
    }

    th.dt-body-justify,
    td.dt-body-justify {
      text-align: justify;
    }

    th.dt-body-nowrap,
    td.dt-body-nowrap {
      white-space: nowrap;
    }
  }

  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;

  td,
  th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  td.dataTables_empty,
  th.dataTables_empty {
    text-align: center;
  }

  &.nowrap {
    th,
    td {
      white-space: nowrap;
    }
  }
}

div.dataTables_wrapper div {
  &.dataTables_length {
    label {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
    }

    select {
      width: 75px;
      display: inline-block;
    }
  }

  &.dataTables_filter {
    text-align: right;

    label {
      font-weight: normal;
      white-space: nowrap;
      text-align: left;
    }

    input {
      margin-left: 0.5em;
      display: inline-block;
      width: auto;
    }
  }

  &.dataTables_info {
    padding-top: 8px;
    white-space: nowrap;
  }

  &.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;

    ul.pagination {
      margin: 2px 0;
      white-space: nowrap;
    }
  }

  &.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
  }
}

table.dataTable thead {
  > tr > {
    th {
      &.sorting_asc,
      &.sorting_desc,
      &.sorting {
        padding-right: 30px;
      }
    }

    td {
      &.sorting_asc,
      &.sorting_desc,
      &.sorting {
        padding-right: 30px;
      }
    }

    th:active,
    td:active {
      outline: none;
    }
  }

  .sorting,
  .sorting_asc,
  .sorting_desc,
  .sorting_asc_disabled,
  .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
  }

  .sorting:after,
  .sorting_asc:after,
  .sorting_desc:after,
  .sorting_asc_disabled:after,
  .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: 'Glyphicons Halflings';
    opacity: 0.5;
  }

  .sorting:after {
    opacity: 0.2;
    content: '';

    /* sort */
  }

  .sorting_asc:after {
    opacity: 0.5;
    content: '';

    /* sort-by-attributes */
  }

  .sorting_desc:after {
    opacity: 0.5;
    content: '';

    /* sort-by-attributes-alt */
  }

  .sorting_asc_disabled:after,
  .sorting_desc_disabled:after {
    color: #eee;
  }
}

div {
  &.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
  }

  &.dataTables_scrollBody > table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    > {
      thead {
        .sorting:after,
        .sorting_asc:after,
        .sorting_desc:after {
          display: none;
        }
      }

      tbody > tr:first-child > {
        th,
        td {
          border-top: none;
        }
      }
    }
  }

  &.dataTables_scrollFoot > .dataTables_scrollFootInner {
    box-sizing: content-box;

    > table {
      margin-top: 0 !important;
      border-top: none;
    }
  }
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div {
    &.dataTables_length,
    &.dataTables_filter,
    &.dataTables_info,
    &.dataTables_paginate {
      text-align: center;
    }
  }
}

table {
  &.dataTable.table-condensed {
    > thead > tr > th {
      padding-right: 20px;
    }

    .sorting:after,
    .sorting_asc:after,
    .sorting_desc:after {
      top: 6px;
      right: 6px;
    }
  }

  &.table-bordered.dataTable {
    border-right-width: 0;

    th,
    td {
      border-left-width: 0;
    }

    th:last-child,
    td:last-child {
      border-right-width: 1px;
    }

    tbody {
      th,
      td {
        border-bottom-width: 0;
      }
    }
  }
}

div {
  &.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
  }

  &.table-responsive > div.dataTables_wrapper > div.row {
    margin: 0;

    > div[class^='col-'] {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

table.dataTable {
  &.dtr-inline.collapsed {
    > tbody > tr {
      > {
        td.child,
        th.child {
          cursor: default !important;
        }

        td {
          &.dataTables_empty {
            cursor: default !important;
          }

          &.child:before {
            display: none !important;
          }
        }

        th.child:before {
          display: none !important;
        }

        td {
          &.dataTables_empty:before {
            display: none !important;
          }

          &.dtr-control {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
          }
        }

        th.dtr-control {
          position: relative;
          padding-left: 30px;
          cursor: pointer;
        }

        td.dtr-control:before,
        th.dtr-control:before {
          top: 50%;
          left: 5px;
          height: 1em;
          width: 1em;
          margin-top: -9px;
          display: block;
          position: absolute;
          color: white;
          border: 0.15em solid white;
          border-radius: 1em;
          box-shadow: 0 0 0.2em #444;
          box-sizing: content-box;
          text-align: center;
          text-indent: 0 !important;
          font-family: 'Courier New', Courier, monospace;
          line-height: 1em;
          content: '+';
          background-color: #337ab7;
        }
      }

      &.parent > {
        td.dtr-control:before,
        th.dtr-control:before {
          content: '-';
          background-color: #d33333;
        }
      }
    }

    &.compact > tbody > tr > {
      td.dtr-control,
      th.dtr-control {
        padding-left: 27px;
      }

      td.dtr-control:before,
      th.dtr-control:before {
        left: 4px;
        height: 14px;
        width: 14px;
        border-radius: 14px;
        line-height: 14px;
        text-indent: 3px;
      }
    }
  }

  &.dtr-column > tbody > tr {
    > {
      td.dtr-control,
      th.dtr-control,
      td.control,
      th.control {
        position: relative;
        cursor: pointer;
      }

      td.dtr-control:before,
      th.dtr-control:before,
      td.control:before,
      th.control:before {
        top: 50%;
        left: 50%;
        height: 0.8em;
        width: 0.8em;
        margin-top: -0.5em;
        margin-left: -0.5em;
        display: block;
        position: absolute;
        color: white;
        border: 0.15em solid white;
        border-radius: 1em;
        box-shadow: 0 0 0.2em #444;
        box-sizing: content-box;
        text-align: center;
        text-indent: 0 !important;
        font-family: 'Courier New', Courier, monospace;
        line-height: 1em;
        content: '+';
        background-color: #337ab7;
      }
    }

    &.parent {
      td.dtr-control:before,
      th.dtr-control:before,
      td.control:before,
      th.control:before {
        content: '-';
        background-color: #d33333;
      }
    }
  }

  > tbody > tr.child {
    padding: 0.5em 1em;

    &:hover {
      background: transparent !important;
    }

    ul.dtr-details {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        border-bottom: 1px solid #efefef;
        padding: 0.5em 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    span.dtr-title {
      display: inline-block;
      min-width: 75px;
      font-weight: bold;
    }
  }
}

div {
  &.dtr-modal {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    padding: 10em 1em;

    div {
      &.dtr-modal-display {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 50%;
        overflow: auto;
        margin: auto;
        z-index: 102;
        overflow: auto;
        background-color: #f5f5f7;
        border: 1px solid black;
        border-radius: 0.5em;
        box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
      }

      &.dtr-modal-content {
        position: relative;
        padding: 1em;
      }

      &.dtr-modal-close {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 22px;
        height: 22px;
        border: 1px solid #eaeaea;
        background-color: #f9f9f9;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        z-index: 12;

        &:hover {
          background-color: #eaeaea;
        }
      }

      &.dtr-modal-background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 101;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &.dtr-bs-modal table.table tr:first-child td {
    border-top: none;
  }
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}

.dt-toolbar-footer,
.dt-toolbar {
  border: 1px solid $border-color;
}

.dt-toolbar {
  border-bottom: none;
}

.dt-toolbar-footer {
  border-top: none;
}

.no-padding .dt-toolbar-footer,
.no-padding .dt-toolbar {
  border: none;
}

.no-padding .dt-toolbar-footer,
.no-padding .dt-toolbar {
  border: none;
}

.no-padding > table,
.no-padding .dataTables_wrapper table {
  border-bottom: 1px solid $border-color !important;
}

.panel > .dataTables_wrapper {
  .dt-toolbar,
  .dt-toolbar-footer,
  table {
    border: 0;
  }
}

.dt-toolbar .input-group-addon {
  height: 24px !important;
  width: 24px;
  padding-top: 4px;
  padding-left: 5px;
}

.dt-toolbar-footer {
  padding: 2 * $spacing/3 $spacing;

  > div:first-child {
    width: 250px !important;
    position: absolute;
  }

  > div:last-child {
    width: 100% !important;
  }
}

.dt-toolbar {
  padding: $spacing $spacing 0;
}

.dt-hidden-info .dt-toolbar-footer > div:first-child {
  display: none;
}

.dt-hidden-info .dt-toolbar-footer div:first-child + div {
  width: 100% !important;
}

.dataTable tr > th {
  border-bottom-width: 1px !important;
}

.dataTables_scrollBody .table-compact th {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}

.dataTables_scroll .dataTables_scrollHeadInner {
  margin-top: 0 !important;
}

.dataTables_scrollHead table {
  margin-top: 0 !important;
}

.datatable-xs .dt-toolbar div {
  width: 100%;
  padding: 0;
}

.datatable-xs .dt-toolbar div:nth-child(2) {
  display: none;
}

.no-padding .widget-body {
  padding-bottom: 0;
}

.datatable-xs .dt-toolbar-footer > div {
  width: 100%;
  float: left;
}

.datatable-xs .dt-toolbar-footer > div:nth-child(2) {
  padding-left: 0;
}

.datatable-xs .dt-toolbar-footer > div:first-child {
  display: none;
}

.datatable-xs .dt-toolbar-footer .dataTables_info {
  text-align: center;
}

.datatable-xs .table {
  margin-bottom: 0 !important;
}

div.dataTables_wrapper {
  font-size: 13px;

  .dataTables_filter label {
    width: 100%;
    position: relative;

    &::before {
      content: '\f002';
      position: absolute;
      font: normal normal normal 14px/1 'FontAwesome5Free';
      top: 10px;
      left: 13px;
      font-size: 11px;
      color: lighten($text-color, 2%);
      opacity: 0.8;
    }
  }

  .dataTables_filter label .input-group-addon {
    position: absolute;
  }

  .dataTables_filter label input {
    width: 100%;
    padding-left: 30px;
    display: block;
    margin-left: 0;
  }

  .row:nth-child(2) .col-sm-12 {
    padding: 0;
  }

  .dt-buttons {
    margin: 0 5px;
    width: auto !important;

    .btn {
      padding: 6px 10px;
      border-radius: 2px;
      font-size: 12px;
    }
  }

  .dt-buttons,
  .dataTables_length,
  .dataTables_info {
    float: left;
  }

  .dataTables_paginate {
    float: right;
  }

  .dataTables_filter,
  .dataTables_length {
    color: $table-filter-color;
    font-size: 12px;
    line-height: 24px;
    margin: 0 5px 5px;

    label {
      margin: 0;
    }

    input,
    select {
      border-radius: 2px;
      height: 31px !important;
      padding: 6px 10px;
      font-size: 12px;
      vertical-align: baseline !important;
    }

    select {
      padding: 2px 15px 2px 4px;
    }
  }

  .dataTables_info,
  .dataTables_paginate {
    margin: 7px !important;
    padding-top: 0 !important;
    line-height: 24px;
    font-size: 12px;
    color: $gray;
  }

  .dataTables_paginate {
    .pagination {
      margin: 0 !important;
      float: right;

      > li {
        a {
          padding: 3px 8px;
        }

        &:first-child {
          > a,
          > span {
            border-bottom-left-radius: 2px;
            border-top-left-radius: 2px;
          }
        }

        &:last-child {
          > a,
          > span {
            border-bottom-right-radius: 2px;
            border-top-right-radius: 2px;
          }
        }
      }
    }
  }
}

table.dataTable {
  clear: none;
  border-bottom: 1px solid $border-color-light;
  margin: 0 !important;

  thead {
    th {
      background-color: $panel-default-heading-bg;
      color: $panel-default-text;
    }

    .sorting:after,
    .sorting_asc:after,
    .sorting_desc:after,
    .sorting_asc_disabled:after,
    .sorting_desc_disabled:after {
      font-family: 'FontAwesome5Free', sans-serif;
    }

    .sorting:after {
      content: '\f0dc';
    }

    .sorting_asc:after {
      content: '\f0de';
    }

    .sorting_desc:after {
      content: '\f0dd';
    }
  }

  .dataTables_empty {
    padding: 0;
    font-size: 14px;

    .alert {
      border-radius: 0;
      border: 0;
    }
  }

  &.hover tbody > tr {
    &.selected:hover,
    > .selected:hover {
      background-color: #0083c4;
    }
  }

  &.order-column tbody > tr {
    &.selected > {
      .sorting_1,
      .sorting_2,
      .sorting_3 {
        background-color: #0085c7;
      }
    }

    > .selected {
      background-color: #0085c7;
    }
  }

  &.order-column.stripe tbody > tr.odd.selected > .sorting_1 {
    background-color: #0080c0;
  }

  &.order-column.hover tbody > tr {
    &:hover > .selected,
    > .selected:hover {
      background-color: #007cbb;
    }
  }

  td.select-checkbox,
  th.select-checkbox,
  td.select-radio-btn,
  th.select-radio-btn {
    position: relative;
    padding: 0 15px;

    &:focus {
      outline: none;
    }

    &:before,
    &:after {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  td.select-radio-btn,
  th.select-radio-btn {
    &:before,
    &:after {
      display: none;
    }
  }

  tr:hover,
  tr.selected {
    td.select-checkbox,
    th.select-checkbox,
    td.select-radio-btn,
    th.select-radio-btn {
      &:before,
      &:after {
        display: block;
      }
    }
  }

  td.select-checkbox:before,
  th.select-checkbox:before,
  td.select-radio-btn:before,
  th.select-radio-btn:before {
    content: ' ';
    margin-top: -9px;
    margin-left: -9px;
    border: 1px solid $table-radio-border;
    background: $table-radio-bg;
  }

  td.select-radio-btn:before,
  th.select-radio-btn:before {
    border-radius: 50%;
  }

  tbody {
    tr.selected a,
    th.selected a,
    td.selected a {
      color: #a2d4ed;
    }

    .dt-body-right {
      text-align: right;
    }
  }

  tr.selected {
    background-color: $table-bg-hover;

    &:hover {
      background-color: $table-bg-hover;
    }

    td.select-checkbox:before,
    th.select-checkbox:before,
    td.select-radio-btn:before,
    th.select-radio-btn:before {
      border-color: $table-radio-active-border;
    }

    td.select-checkbox:after,
    th.select-checkbox:after {
      content: '\f00c';
      font: normal normal normal 14px/1 'FontAwesome5Free';
      margin-top: -9px;
      margin-left: -9px;
      text-align: center;
      line-height: 18px;
      color: $btn-primary-border;
    }

    td.select-radio-btn:after,
    th.select-radio-btn:after {
      content: '';
      margin-top: -5px;
      margin-left: -5px;
      background-color: $btn-primary-bg;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  td.control,
  th.control {
    padding: 0 15px;
  }

  &.dtr-column > tbody > tr {
    > td.control:before {
      content: '\f055';
      border-radius: 0;
      border: 0;
      box-shadow: none;
      font: normal normal normal 17px/1 'FontAwesome5Free';
      color: $text-color;
      background: none;
      width: 20px;
      height: 20px;
      line-height: 19px;
    }

    &.parent {
      td.control:before {
        content: '\f056';
        background: none;
      }
    }

    &.child ul.dtr-details {
      display: block;

      .fas-info,
      .fas-info-circle {
        display: none;
      }

      .dtr-title {
        min-width: 100px;
        margin-right: 10px;
      }
    }
  }

  td.buttons {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 30px;

    .action {
      &.disabled {
        &,
        * {
          pointer-events: none;
          opacity: 0.6;
        }
      }
    }
  }
}

.dataTables_scrollBody {
  th.select-checkbox {
    border: none;

    &:before,
    &:after {
      display: none !important;
    }
  }
}

.select-checkbox.hide-checkbox {
  &:before,
  &:after {
    display: none !important;
  }
}

table.dataTable.dt-details-title-hidden {
  > tbody {
    > tr.child {
      .dtr-details {
        .dtr-title {
          display: none;
        }
      }
    }
  }
}

div.dataTables_wrapper span {
  &.select-info,
  &.select-item {
    margin-left: 0.5em;
  }
}

@media screen and (max-width: 640px) {
  div.dataTables_wrapper span {
    &.select-info,
    &.select-item {
      margin-left: 0;
      display: block;
    }
  }
}

.dataTable-toolbar {
  padding: 5px 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid $border-color;
}

.inbox {
  table.dataTable {
    tr {
      background: $table-odd-bg;

      &.unread > td {
        font-weight: bold;
        background: lighten($table-odd-bg, 5%);
      }

      &.active > td {
        background-color: $green-base;
        color: #fff;
      }
    }
  }

  .ag-root-wrapper {
    .ag-row {
      cursor: pointer;
      .ag-cell {
        background: $table-odd-bg;
      }

      &.unread > .ag-cell {
        font-weight: bold;
        background: lighten($table-odd-bg, 5%);
      }

      &.ag-row-selected > .ag-cell {
        background-color: $green-base;
        color: #fff;

        a {
          color: #fff;
        }
      }
    }
  }
}
