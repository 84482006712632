/* jsTree default theme */

.jstree-node,
.jstree-children,
.jstree-container-ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

.jstree-node {
  white-space: nowrap;
}

.jstree-anchor {
  display: inline-block;
  color: black;
  white-space: nowrap;
  padding: 0 4px 0 1px;
  margin: 0;
  vertical-align: top;

  &:focus {
    outline: 0;
  }

  text-decoration: none;
  color: inherit;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  > .flex-content {
    display: inline-flex;
    width: calc(100% - 31px);

    > .flex-content__text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  code {
    border: 1px solid $border-color;
    background-color: transparent;
    color: $text-color;
    border-radius: 0.2rem;
    padding: 0.1rem 0.2rem;
    width: 2.5rem;
    display: inline-block;
    line-height: normal;
    text-align: center;
    margin-left: 0.2rem;
    vertical-align: middle;

    &.counter {
      background-color: $btn-primary-bg;
      color: $btn-primary-color;
      border: none;
    }
  }
}

.jstree-checkbox-selection {
  .jstree-anchor {
    > .flex-content {
      width: calc(100% - 43px);
    }
  }
}

.jstree-icon {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;

  &:empty {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center;
  }
}

.jstree-ocl {
  cursor: pointer;
}

.jstree-leaf > .jstree-ocl {
  cursor: default;
}

.jstree {
  .jstree-open > .jstree-children {
    display: block;
  }

  .jstree-closed > .jstree-children,
  .jstree-leaf > .jstree-children {
    display: none;
  }
}

.jstree-anchor > .jstree-themeicon {
  margin-right: 2px;
}

.jstree-no-icons .jstree-themeicon,
.jstree-anchor > .jstree-themeicon-hidden,
.jstree-hidden,
.jstree-node.jstree-hidden {
  display: none;
}

.jstree-rtl {
  .jstree-anchor {
    padding: 0 1px 0 4px;

    > .jstree-themeicon {
      margin-left: 2px;
      margin-right: 0;
    }
  }

  .jstree-node {
    margin-left: 0;
  }

  .jstree-container-ul > .jstree-node {
    margin-right: 0;
  }
}

.jstree-wholerow-ul {
  position: relative;
  display: inline-block;
  min-width: 100%;

  .jstree-leaf > .jstree-ocl {
    cursor: pointer;
  }

  .jstree-anchor,
  .jstree-icon {
    position: relative;
  }

  .jstree-wholerow {
    width: 100%;
    cursor: pointer;
    position: absolute;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.jstree-contextmenu .jstree-anchor {
  -webkit-user-select: none;

  /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none;

  /* disable the IOS popup when long-press on a link */
  user-select: none;
}

.vakata-context {
  display: none;
  margin: 0;
  padding: 2px;
  position: absolute;
  background: #f5f5f5;
  border: 1px solid #979797;
  box-shadow: 2px 2px 2px #999999;

  ul {
    margin: 0;
    padding: 2px;
    position: absolute;
    background: #f5f5f5;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px #999999;
    list-style: none;
    left: 100%;
    margin-top: -2.7em;
    margin-left: -4px;
  }

  .vakata-context-right ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px;
  }

  li {
    list-style: none;

    > a {
      display: block;
      padding: 0 2em 0 2em;
      text-decoration: none;
      width: auto;
      color: black;
      white-space: nowrap;
      line-height: 2.4em;
      text-shadow: 1px 1px 0 white;
      border-radius: 1px;

      &:hover {
        position: relative;
        background-color: #e8eff7;
        box-shadow: 0 0 2px #0a6aa1;
      }

      &.vakata-context-parent {
        background-image: url('data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==');
        background-position: right center;
        background-repeat: no-repeat;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  .vakata-context-no-icons {
    margin-left: 0;
  }

  .vakata-context-hover > a {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1;
  }

  .vakata-context-separator > a {
    background: white;
    border: 0;
    border-top: 1px solid #e2e3e3;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    padding: 0;
    margin: 0 0 0 2.4em;
    border-left: 1px solid #e0e0e0;
    text-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
    border-radius: 0;

    &:hover {
      background: white;
      border: 0;
      border-top: 1px solid #e2e3e3;
      height: 1px;
      min-height: 1px;
      max-height: 1px;
      padding: 0;
      margin: 0 0 0 2.4em;
      border-left: 1px solid #e0e0e0;
      text-shadow: 0 0 0 transparent;
      box-shadow: 0 0 0 transparent;
      border-radius: 0;
    }
  }

  .vakata-contextmenu-disabled {
    a {
      color: silver;
      background-color: transparent;
      border: 0;
      box-shadow: 0 0 0;

      &:hover {
        color: silver;
        background-color: transparent;
        border: 0;
        box-shadow: 0 0 0;
      }
    }

    > a > i {
      filter: grayscale(100%);
    }
  }

  li > a {
    > i {
      text-decoration: none;
      display: inline-block;
      width: 2.4em;
      height: 2.4em;
      background: transparent;
      margin: 0 0 0 -2em;
      vertical-align: top;
      text-align: center;
      line-height: 2.4em;

      &:empty {
        width: 2.4em;
        line-height: 2.4em;
      }
    }

    .vakata-contextmenu-sep {
      display: inline-block;
      width: 1px;
      height: 2.4em;
      background: white;
      margin: 0 0.5em 0 0;
      border-left: 1px solid #e2e3e3;
    }
  }

  .vakata-contextmenu-shortcut {
    font-size: 0.8em;
    color: silver;
    opacity: 0.5;
    display: none;
  }
}

.vakata-context-rtl {
  ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px;
  }

  li > a.vakata-context-parent {
    background-image: url('data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7');
    background-position: left center;
    background-repeat: no-repeat;
  }

  .vakata-context-separator > a {
    margin: 0 2.4em 0 0;
    border-left: 0;
    border-right: 1px solid #e2e3e3;
  }

  .vakata-context-left ul {
    right: auto;
    left: 100%;
    margin-left: -4px;
    margin-right: auto;
  }

  li > a {
    > i {
      margin: 0 -2em 0 0;
    }

    .vakata-contextmenu-sep {
      margin: 0 0 0 0.5em;
      border-left-color: white;
      background: #e2e3e3;
    }
  }
}

#jstree-marker {
  position: absolute;
  top: 0;
  left: 0;
  margin: -5px 0 0 0;
  padding: 0;
  border-right: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}

#jstree-dnd {
  line-height: 16px;
  margin: 0;
  padding: 4px;

  .jstree-icon,
  .jstree-copy {
    display: inline-block;
    text-decoration: none;
    margin: 0 2px 0 0;
    padding: 0;
    width: 16px;
    height: 16px;
  }

  .jstree-ok {
    background: green;
  }

  .jstree-er {
    background: red;
  }

  .jstree-copy {
    margin: 0 2px 0 2px;
  }
}

.jstree-default {
  .jstree-node,
  .jstree-icon {
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .jstree-anchor,
  .jstree-animated,
  .jstree-wholerow {
    transition: background-color 0.15s, box-shadow 0.15s;
  }

  .jstree-hovered,
  .jstree-context {
    background: lighten($green-base, 5%);
    color: #fff;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #cccccc;
  }

  .jstree-clicked {
    background: $green-base;
    border-radius: 2px;
    box-shadow: inset 0 0 1px $border-color;
    color: #fff;

    &.jstree-hovered {
      color: #fff;
    }
  }

  .jstree-hovered,
  .jstree-clicked {
    code {
      color: #f5f5f5;
    }
  }

  &.jstree-checkbox-no-clicked {
    .jstree-clicked:not(.jstree-hovered) {
      color: $text-color;

      code {
        color: $text-color;
      }
    }
  }

  .jstree-no-icons .jstree-anchor > .jstree-themeicon {
    display: none;
  }

  .jstree-disabled {
    background: transparent;
    color: #666666;

    &.jstree-hovered {
      background: transparent;
      box-shadow: none;
    }

    &.jstree-clicked {
      background: #efefef;
    }

    > .jstree-icon {
      opacity: 0.8;

      /* Firefox 10+ */
      filter: gray;

      /* IE6-9 */
      -webkit-filter: grayscale(100%);

      /* Chrome 19+ & Safari 6+ */
    }
  }

  .jstree-search {
    font-style: italic;
    color: #8b0000;
    font-weight: bold;
  }

  .jstree-no-checkboxes .jstree-checkbox {
    display: none !important;
  }

  &.jstree-checkbox-no-clicked {
    .jstree-clicked {
      background: transparent;
      box-shadow: none;

      &.jstree-hovered {
        background: lighten($green-base, 5%);
      }
    }

    > .jstree-wholerow-ul .jstree-wholerow-clicked {
      background: transparent;

      &.jstree-wholerow-hovered {
        background: lighten($green-base, 5%);
      }
    }
  }

  > {
    .jstree-striped {
      min-width: 100%;
      display: inline-block;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==')
        left top repeat;
    }

    .jstree-wholerow-ul {
      .jstree-hovered,
      .jstree-clicked {
        background: transparent;
        box-shadow: none;
        border-radius: 0;
      }
    }
  }

  .jstree-wholerow {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .jstree-wholerow-hovered {
    background: lighten($green-base, 5%);
  }

  .jstree-wholerow-clicked {
    background: #beebff;
    background: -webkit-linear-gradient(top, #beebff 0%, #a8e4ff 100%);
    background: linear-gradient(to bottom, #beebff 0%, #a8e4ff 100%);
  }

  .jstree-node {
    min-height: 24px;
    line-height: 24px;
    margin-left: 24px;
    min-width: 24px;
  }

  .jstree-anchor {
    line-height: 24px;
    height: 24px;
  }

  .jstree-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;

    &:empty {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }

  &.jstree-rtl .jstree-node {
    margin-right: 24px;
  }

  .jstree-wholerow {
    height: 24px;
  }

  .jstree-node {
    background-position: -292px -4px;
    background-repeat: repeat-y;
  }

  .jstree-last {
    background: transparent;
  }

  .jstree-open > .jstree-ocl {
    background-position: -132px -4px;
  }

  .jstree-closed > .jstree-ocl {
    background-position: -100px -4px;
  }

  .jstree-leaf > .jstree-ocl {
    background-position: -68px -4px;
  }

  .jstree-themeicon {
    background-position: -260px -4px;
  }

  > .jstree-no-dots {
    .jstree-node,
    .jstree-leaf > .jstree-ocl {
      background: transparent;
    }

    .jstree-open > .jstree-ocl {
      background-position: -36px -4px;
    }

    .jstree-closed > .jstree-ocl {
      background-position: -4px -4px;
    }
  }

  .jstree-disabled {
    background: transparent;

    &.jstree-hovered {
      background: transparent;
    }

    &.jstree-clicked {
      background: #efefef;
    }
  }

  .jstree-checkbox {
    background-position: -164px -4px;

    &:hover {
      background-position: -164px -36px;
    }
  }

  &.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
  .jstree-checked > .jstree-checkbox {
    background-position: -228px -4px;
  }

  &.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
  .jstree-checked > .jstree-checkbox:hover {
    background-position: -228px -36px;
  }

  .jstree-anchor > .jstree-undetermined {
    background-position: -196px -4px;

    &:hover {
      background-position: -196px -36px;
    }
  }

  .jstree-checkbox-disabled {
    opacity: 0.8;

    /* Firefox 10+ */
    filter: gray;

    /* IE6-9 */
    -webkit-filter: grayscale(100%);

    /* Chrome 19+ & Safari 6+ */
  }

  > .jstree-striped {
    background-size: auto 48px;
  }

  &.jstree-rtl {
    .jstree-node {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==');
      background-position: 100% 1px;
      background-repeat: repeat-y;
    }

    .jstree-last {
      background: transparent;
    }

    .jstree-open > .jstree-ocl {
      background-position: -132px -36px;
    }

    .jstree-closed > .jstree-ocl {
      background-position: -100px -36px;
    }

    .jstree-leaf > .jstree-ocl {
      background-position: -68px -36px;
    }

    > .jstree-no-dots {
      .jstree-node,
      .jstree-leaf > .jstree-ocl {
        background: transparent;
      }

      .jstree-open > .jstree-ocl {
        background-position: -36px -36px;
      }

      .jstree-closed > .jstree-ocl {
        background-position: -4px -36px;
      }
    }
  }

  .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
  }

  > .jstree-container-ul > .jstree-node {
    margin-left: 0;
    margin-right: 0;
  }
}

#jstree-dnd.jstree-default {
  line-height: 24px;
  padding: 0 4px;

  .jstree-ok,
  .jstree-er {
    background-repeat: no-repeat;
    background-color: transparent;
  }

  i {
    background: transparent;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .jstree-ok {
    background-position: -4px -68px;
  }

  .jstree-er {
    background-position: -36px -68px;
  }
}

.jstree-default {
  .jstree-ellipsis {
    overflow: hidden;

    .jstree-anchor {
      width: calc(100% - 29px);
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.jstree-rtl {
    .jstree-node {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==');
    }

    .jstree-last {
      background: transparent;
    }
  }
}

@media (max-width: 768px) {
  #jstree-dnd.jstree-dnd-responsive {
    line-height: 40px;
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 1px 1px white;

    > {
      i {
        background: transparent;
        width: 40px;
        height: 40px;
      }

      .jstree-ok {
        background-position: 0 -200px;
        background-size: 120px 240px;
      }

      .jstree-er {
        background-position: -40px -200px;
        background-size: 120px 240px;
      }
    }
  }

  #jstree-marker.jstree-dnd-responsive {
    border-left-width: 10px;
    border-top-width: 10px;
    border-bottom-width: 10px;
    margin-top: -10px;
  }
}

@media (max-width: 768px) {
  .jstree-default-responsive {
    /*
	.jstree-open > .jstree-ocl,
	.jstree-closed > .jstree-ocl { border-radius:20px; background-color:white; }
	*/

    .jstree-node,
    .jstree-leaf > .jstree-ocl {
      background: transparent;
    }

    .jstree-node {
      min-height: 40px;
      line-height: 40px;
      margin-left: 40px;
      min-width: 40px;
      white-space: nowrap;
    }

    .jstree-anchor {
      line-height: 40px;
      height: 40px;
    }

    .jstree-icon {
      width: 40px;
      height: 40px;
      line-height: 40px;

      &:empty {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }

    > .jstree-container-ul > .jstree-node {
      margin-left: 0;
    }

    &.jstree-rtl {
      .jstree-node {
        margin-left: 0;
        margin-right: 40px;
        background: transparent;
      }

      .jstree-container-ul > .jstree-node {
        margin-right: 0;
      }
    }

    .jstree-ocl,
    .jstree-themeicon,
    .jstree-checkbox {
      background-size: 120px 240px;
    }

    .jstree-leaf > .jstree-ocl,
    &.jstree-rtl .jstree-leaf > .jstree-ocl {
      background: transparent;
    }

    .jstree-open > .jstree-ocl {
      background-position: 0 0 !important;
    }

    .jstree-closed > .jstree-ocl {
      background-position: 0 -40px !important;
    }

    &.jstree-rtl .jstree-closed > .jstree-ocl {
      background-position: -40px 0 !important;
    }

    .jstree-themeicon {
      background-position: -40px -40px;
    }

    .jstree-checkbox {
      background-position: -40px -80px;

      &:hover {
        background-position: -40px -80px;
      }
    }

    &.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
    .jstree-checked > .jstree-checkbox {
      background-position: 0 -80px;

      &:hover {
        background-position: 0 -80px;
      }
    }

    .jstree-anchor {
      > .jstree-undetermined {
        background-position: 0 -120px;

        &:hover {
          background-position: 0 -120px;
        }
      }

      font-weight: bold;
      font-size: 1.1em;
      text-shadow: 1px 1px white;
    }

    > .jstree-striped {
      background: transparent;
    }

    .jstree-wholerow {
      border-top: 1px solid rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid rgba(64, 64, 64, 0.2);
      background: #ebebeb;
      height: 40px;
    }

    .jstree-wholerow-hovered {
      background: lighten($green-base, 5%);
    }

    .jstree-wholerow-clicked {
      background: #beebff;
    }

    .jstree-children {
      .jstree-last > .jstree-wholerow {
        box-shadow: inset 0 -6px 3px -5px #666666;
      }

      .jstree-open {
        > .jstree-wholerow {
          box-shadow: inset 0 6px 3px -5px #666666;
          border-top: 0;
        }

        + .jstree-open {
          box-shadow: none;
        }
      }
    }

    .jstree-node,
    .jstree-icon,
    .jstree-node > .jstree-ocl,
    .jstree-themeicon,
    .jstree-checkbox {
      background-size: 120px 240px;
    }

    .jstree-node {
      background-position: -80px 0;
      background-repeat: repeat-y;
    }

    .jstree-last {
      background: transparent;
    }

    .jstree-leaf > .jstree-ocl {
      background-position: -40px -120px;
    }

    .jstree-last > .jstree-ocl {
      background-position: -40px -160px;
    }

    .jstree-themeicon-custom {
      background-color: transparent;
      background-image: none;
      background-position: 0 0;
    }

    .jstree-file {
      background-size: 120px 240px;
    }

    .jstree-folder {
      background-size: 120px 240px;
    }

    > .jstree-container-ul > .jstree-node {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

js-tree {
  .tree-options {
    margin-bottom: 5px;
    padding: 5px;
    position: relative;
    border: 1px solid $border-color-light;
    background: $gray-lighter;

    .expand-all {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
      border-left: 1px solid $border-color-light;
      padding: 5px;

      & + .search {
        margin-right: 40px;
      }
    }
  }
}

.tree {
  margin-left: -5px;

  .jstree-node,
  .jstree-icon {
    background-image: none;
  }

  .jstree-container-ul {
    display: block;

    .jstree-loading {
      .jstree-ocl {
        background-image: none !important;
      }
    }

    .jstree-node {
      position: relative;
      min-height: 0 !important;
      line-height: 25px !important;
      margin-left: 15px;

      .jstree-ocl {
        position: absolute;
        background-image: none !important;
        display: inline-block;
        font: normal normal normal 14px/1 'FontAwesome5Free';
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        color: lighten($gray, 10%);

        &:after {
          content: '\f0da';
          font-size: 14px;
          display: inherit;
        }
      }

      &.jstree-leaf {
        .jstree-ocl:after {
          content: '';
        }

        &[data-status='online'],
        &[data-status='ONLINE'],
        &[data-status='offline'],
        &[data-status='OFFLINE'],
        &[data-status='outdated'],
        &[data-status='OUTDATED'],
        &[data-status='undefined'],
        &[data-status='UNKNOWN'] {
          > .jstree-ocl {
            width: 20px;
            margin-left: 5px;

            &:after {
              content: '\f111';
              font-size: 8px;
            }
          }
        }
      }

      &[data-status='online'],
      &[data-status='ONLINE'] {
        > .jstree-ocl:after {
          color: $brand-success;
        }
      }

      &[data-status='offline'],
      &[data-status='OFFLINE'] {
        > .jstree-ocl:after {
          color: $brand-danger;
        }
      }

      &[data-status='outdated'],
      &[data-status='OUTDATED'] {
        > .jstree-ocl:after {
          color: $brand-warning;
        }
      }

      &[data-status='undefined'],
      &[data-status='UNKNOWN'] {
        > .jstree-ocl:after {
          color: $gray;
        }
      }

      .jstree-icon:empty {
        width: 25px;
        height: 25px;
        line-height: 25px;
      }

      .jstree-anchor {
        padding-left: 25px;
        font-size: 13px;
        line-height: 25px;
        height: auto;
        display: block;

        .jstree-icon {
          background: none no-repeat;

          &.jstree-themeicon-custom {
            background-size: 16px !important;
          }

          &.jstree-checkbox {
            width: 16px !important;
            height: 16px;
            text-align: center;
            vertical-align: text-bottom;
            position: relative;
            font: normal normal normal 15px 'FontAwesome5Free';

            &:before {
              content: '';
              border: 1px solid $text-color;
              display: inline-block;
              vertical-align: text-bottom;
              width: 16px;
              height: 16px;
              position: absolute;
              left: 0;
              top: 0;
              line-height: 16px;
            }

            &.jstree-undetermined:after {
              position: absolute;
              content: '\f068' !important;
              left: 3px;
              top: 2px;
              font-size: 12px;
            }
          }

          &:empty {
            width: 0;

            &.jstree-themeicon-custom {
              width: 25px;
            }
          }
        }

        &.jstree-clicked {
          .jstree-icon {
            &.jstree-checkbox {
              &:after {
                font-size: 11px;
                position: absolute;
                // color: $gray-lightest;
                content: '\f00c';
                left: 2px;
                top: 3px;
              }
            }
          }
        }
      }

      &.jstree-open {
        > {
          .jstree-ocl:after {
            transform: rotate(45deg);
          }
        }
      }
    }

    > .jstree-node {
      margin-left: 0;
    }

    .jstree-search {
      color: $state-info-text;
      font-style: normal;
    }
  }

  &.jstree-checkbox-selection {
    .jstree-container-ul {
      .jstree-node {
        .jstree-anchor .jstree-icon:empty {
          width: 25px;
        }
      }
    }
  }
}

.tree-toolbar {
  margin: -10px -10px 10px;
  padding: 10px;
  border-bottom: 1px solid $border-color;
}

.slide-tree {
  .tree {
    position: absolute;
    left: -230px;
    width: 218px;

    &.active {
      transition: 0.3s;
      display: block;
      left: 5px;
    }
  }
}
