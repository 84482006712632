.x-plugin-icon {
  display: inline-block;
  width: 120px;
  padding: 5px;
  margin-bottom: 5px;

  .caption {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    margin-top: -15px;
  }
}
