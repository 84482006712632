@keyframes wf-alert-flashing {
  25% {
    color: #e6220f;
    font-size: 80px;
  }
  75% {
    color: #dc281e;
    font-size: 120px;
  }
}

@keyframes marker-label-flashing {
  0% {
    color: #fff;
    background: #dc281e;
    border-color: #dc281e;
  }
  50% {
    color: #fff;
    background: #dc281e;
    border-color: #dc281e;
  }
  100% {
    border-color: #000;
    background: #000;
    color: #fff;
  }
}

.gmaps-info {
  > div {
    width: 250px;
  }

  .asset-title,
  .info-window-header {
    line-height: 25px;
    width: 100%;
    font-size: 14px;
    left: 0;
    top: 0;
    background: $panel-default-heading-bg;
    color: $text-color;
    padding: 0 20px 0 20px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .sensors,
  .info-window-body {
    margin-top: 30px;
    background: $panel-bg;
    -webkit-box-shadow: 04px 19px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 19px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .controls {
    background: #fff;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    -webkit-box-shadow: 04px 19px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 19px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .btn {
      text-transform: uppercase;
      font-weight: bold;
    }

    h5 {
      margin: 5px 0;
      font-size: 13px;
      font-weight: bold;
      background: $body-bg;
    }
  }

  .label-main {
    position: absolute;
    margin-left: 4px;
    font-size: 13px;
    padding: 3px 4px;

    .fas {
      width: 11px;
    }
  }

  .sensor-name {
    line-height: 19px;
    text-align: left;

    span {
      padding-right: 5px;
    }
  }

  .sensor-value .label {
    display: block;
    font-size: 13px;
    font-weight: normal;
  }
}

.gm-style-iw-t {
  &:after {
    top: -1px !important;
  }
}

.gm-style-iw-tc::after {
  background-color: $panel-bg !important;
}

.gm-style-iw-custom {
  width: auto !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  font-size: 14px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  background: transparent !important;

  .gm-style-iw-chr {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;

    button {
      width: 25px !important;
      height: 25px !important;

      > span {
        display: none !important;
      }

      &::after {
        content: '\f00d';
        font-family: 'FontAwesome5Free';
        font-weight: 900;
        font-size: 14px;
        text-rendering: auto;
        color: #fff;
      }
    }
  }

  > div {
    max-width: none !important;
    overflow: hidden !important;
  }

  .gmaps-info {
    > div {
      width: 160px;
    }

    .asset-title,
    .sensors,
    .info-window-body,
    .info-window-header {
      width: 100%;
      left: 0;
    }

    .asset-title,
    .info-window-header {
      top: 0;
      background: lighten($brand-primary, 5%);
      color: #fff;
    }

    .info-window-header {
      padding: 0 6px;
      height: 25px;
      line-height: 25px;
      padding-right: 25px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: bottom;

      .header-icon {
        display: inline-block;
        width: 25px;
        text-align: center;

        img {
          width: 14px;
        }
      }
    }

    .sensors,
    .info-window-body {
      top: 25px;
      margin-top: 0;
      padding: 3px 6px;
    }

    .sensors {
      padding-right: 27px;
    }

    .controls {
      position: absolute;
      left: 0;
      right: 0;
      width: 100% !important;
    }
  }

  > button {
    right: 0 !important;
    top: 0 !important;
    width: 25px !important;
    height: 25px !important;
    color: #fff;
    text-align: center;

    display: inline-block;
    font: normal normal normal 14px/1 'FontAwesome5Free';
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::after {
      line-height: 25px;
      content: '\f00d';
    }

    img {
      display: none !important;
    }
  }
}

.label.label-marker {
  background: $panel-bg;
  color: $text-color;
  border: 1px solid $border-color;
  box-shadow: 2px 2px darken($border-color, 5%);
  padding: 2px 4px;

  &.flashing {
    -webkit-animation: marker-label-flashing 2s linear infinite alternate;
    -moz-animation: marker-label-flashing 2s linear infinite alternate;
    -o-animation: marker-label-flashing 2s linear infinite alternate;
    animation: marker-label-flashing 2s linear infinite alternate;
    border-color: #dc281e;
  }
}

@mixin label-marker {
  background: #000;
  color: #fff;
  border: 1px solid #666;
  font-size: 13px;
  box-shadow: 1px 1px #999;
  line-height: 14px;
  padding: 0 2px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 0;
}

.distance-marker {
  background: #fff;
  color: #333;
  border: 1px solid #666;
  font-size: 13px;
  box-shadow: 1px 1px #999;
  margin-top: 18px;
  padding: 0 2px;
}

.trace-name-marker {
  background: #fff;
  color: #333;
  border: 1px solid #666;
  font-size: 14px;
  box-shadow: 1px 1px #999;
  margin-top: 18px;
  padding: 0 5px;
  font-weight: bold;
}

.label-marker {
  @include label-marker;
}

.label-marker.label-marker-lg {
  background-color: #ffffff;
  font-size: 20px;
  padding: 3px 5px;
  line-height: 21px;
  min-width: 40px;
}

.label-marker-temperature {
}

.label-marker-humidity {
}

.label-marker-wind {
  height: 70px;
  background: transparent;
  border: none;
  box-shadow: none;

  > div {
    @include label-marker;
    background-color: #ffffff;
    font-size: 20px;
    padding: 3px 5px;
    line-height: 21px;
    min-width: 40px;
  }

  > .arrow {
    position: absolute;
    top: 52px;
    transform: translate(-50%, -50%);

    &:after {
      text-align: center;
      padding-left: 2px;
      border: 2px solid #000;
      background: #fff;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      content: '\f061';
      display: inline-block;
      font: normal normal normal 14px/1 'FontAwesome5Free';
      font-size: 28px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.gis-wildfire-value {
  margin: 0 -10px 10px;
  font-size: 16px;
  line-height: 20px;

  > div {
    padding: 5px 10px;
  }

  label {
    width: 120px;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 0;
    line-height: 22px;
    vertical-align: middle;
  }
}

.wildfire-alert-icon {
  line-height: 120px;
  height: 120px;

  .fas {
    font-size: 120px;
    vertical-align: middle;
    -webkit-animation: wf-alert-flashing 1s infinite;
    -moz-animation: wf-alert-flashing 1s infinite;
    -o-animation: wf-alert-flashing 1s infinite;
    animation: wf-alert-flashing 1s infinite;
  }
}

.wildfire {
  .view-sidebar {
    color: #fff;
    font-size: 15px;
    background: rgba($navbar-inverse-bg, 0.8);
    border-color: $navbar-inverse-border;

    img.modeImg {
      display: block;
      width: auto;
      height: 70px;
      margin: 15px auto;
    }

    label.modeSelect {
      cursor: pointer;
      display: inline-block;
      margin-right: 5px;
      padding: 5px;
      width: 40px;
      height: 40px;
      text-align: center;
      background: #000;

      &.active {
        background: $btn-success-bg;
      }

      input[type='radio'] {
        display: none;
      }

      img {
        height: 30px;
        display: inline-block;
      }
    }

    h5 {
      background: $navbar-inverse-bg;
      color: #fff;
      border-color: $navbar-inverse-border;
      font-size: 15px;
      padding: 5px 10px;
    }
  }

  .bottom-bar {
    background: rgba($navbar-inverse-bg, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    height: 0;
    border-top: 1px solid $navbar-inverse-border;
    padding: 0 !important;
    z-index: 999;

    &.opened {
      height: 295px;

      & + .view-sidebar {
        bottom: 295px;
      }
    }
  }

  .wf-alert-item {
    border: 1px solid #fff;
    padding: 5px 10px 0 10px;

    .timer {
      margin: 0 -10px;
      line-height: 18px;
      padding: 5px;
      text-align: center;
      background: lighten($navbar-inverse-bg, 5%);
    }

    .wf-asset-name {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }

  .vertical-tabs {
    .nav.nav-tabs {
      position: absolute;
      background: transparent;
      z-index: 999;
      margin: 0;
      padding: 0;
      width: 674px;
      list-style: none;
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(-90deg) translate(-674px, 0px);
      -moz-transform: rotate(-90deg) translate(-674px, 0px);
      -o-transform: rotate(-90deg) translate(-674px, 0px);
      transform: rotate(-90deg) translate(-674px, 0px);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
      border-color: $navbar-inverse-border;

      & + .tab-content {
        padding-left: 47px;
      }

      > li > a {
        font-size: 14px;
        color: #fff;
        min-width: 0;

        uib-tab-heading {
          width: 188px;
          display: block;

          &.collapser {
            width: 22px;
          }
        }
      }

      & > li > a:hover,
      & > li > a:focus {
        background-color: lighten($navbar-inverse-bg, 3%) !important;
        border-color: $navbar-inverse-border;
      }

      & > li.active > a,
      & > li.active > a.focus,
      & > li.active > a:hover {
        background-color: lighten($navbar-inverse-bg, 5%) !important;
        border-color: $navbar-inverse-border;
      }
    }
  }
}

.map-omnibox {
  position: absolute;
  top: 5px;
  left: 5px;

  .btn {
    padding: 6px;

    .fas {
      font-size: 1.1em;
    }
  }
}

.map-legend {
  position: absolute;
  bottom: 5px;
  left: 5px;
  margin: 0;
  background: #fff;
  padding: 7px;
  list-style: none;

  > li {
    line-height: 18px;
    padding: 1px 0;

    &.title {
      border-bottom: 1px solid $border-color;
      margin-bottom: 7px;
      font-weight: bold;
      padding-bottom: 3px;
    }
  }

  .element-color {
    width: 14px;
    height: 14px;
    border: 1px $border-color;
    display: inline-block;

    & + label {
      font-weight: normal;
      display: inline-block;
      margin: 0;
      vertical-align: text-top;
    }
  }
}
