.measurement-paper {
  position: absolute;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;

  canvas {
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  #grid {
    opacity: 0.7;
  }
}
