/*
 * File inputs
 */

.input-file {
  position: relative;
  box-sizing: border-box;
  > input {
    box-sizing: border-box;
    background: $input-bg;
    padding: 5px 10px;
  }
}

.input-file .button {
  position: absolute;
  top: 4px;
  right: 4px;
  float: none;
  height: 26px;
  margin: 0;
  padding: 0 14px;
  font-size: 13px;
  line-height: 26px;

  background-color: $btn-primary-bg;
  opacity: 0.8;
  color: $btn-primary-color;
  transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;

  &:hover {
    box-shadow: none;
    opacity: 1;
  }

  input {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
  }
}

/*
 * toggles
 */
.smart-form .toggle {
  margin-bottom: 4px;
  padding-right: 0px;
  font-size: 13px;
  line-height: 25px;
  color: $text-color;
  cursor: pointer;
  position: relative;
  width: 52px;
  height: 27px;

  &:last-child {
    margin-bottom: 0;
  }

  input {
    position: absolute;
    left: -9999px;
    &:checked + i:after {
      content: attr(data-swchon-text) !important;
      text-align: right;
    }
    &:checked + i:before {
      right: 36px;
    }
  }

  i {
    content: '';
    position: absolute;
    top: 4px;
    right: 0;
    display: block;
    width: 49px;
    height: 17px;
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    background: $input-bg;
    &:after {
      content: attr(data-swchoff-text);
      position: absolute;
      top: 1px;
      right: 8px;
      left: 8px;
      font-style: normal;
      font-size: 9px;
      line-height: 13px;
      font-weight: 700;
      text-align: left;
      color: $text-color;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 3px;
      right: 4px;
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      opacity: 1;
      transition: right 0.2s;
      -o-transition: right 0.2s;
      -moz-transition: right 0.2s;
      -webkit-transition: right 0.2s;
    }
  }

  &.testmode {
    input + i {
      border-color: #999999;
    }
    input + i:before {
      background-color: #999999;
    }

    input:checked + i {
      border-color: #c79121;
    }

    input:checked + i:before {
      background-color: #c79121;
    }
  }
}
