::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background: $green-base;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($green-base, 5%);
}

*:focus {
  outline-color: transparent;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

textarea {
  resize: none;
}

ul {
  padding-left: 35px;

  &.titled {
    &:before {
      content: attr(aria-label);
      text-transform: uppercase;
      font-size: 0.9em;
      color: $text-muted;
      font-weight: bold;
      margin-left: -35px;
    }

    &.list-unstyled:before {
      margin-left: 0;
    }
  }
}

html,
body {
  height: 100%;
  background: $navbar-inverse-bg;
}

#page-wrapper {
  padding: 0;
  background-color: $body-bg;
  position: relative;
  overflow: auto;
}

.absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.relative {
  position: relative;
}

.page-header + .absolute {
  top: 70px;
}

.page-header.page-header-sm + .absolute {
  top: 40px;
}

.page-header.page-header-lg + .absolute {
  top: 80px;
}

@media (min-width: $screen-sm-min) {
  #page-wrapper {
    position: absolute;
    bottom: 0;
    top: $navbar-height + 1;
    left: 0;
    right: 0;
    margin: 0 0 0 200px;
    padding: 0;
  }

  .app-message + div {
    #page-wrapper {
      top: 84px;
    }
  }
}

@media (max-width: $screen-ms-max) {
  #page-wrapper {
    position: relative;
    min-height: 100%;
  }

  .absolute.main {
    position: relative;
    height: 100%;
    top: 0;
  }

  .page-header + .absolute {
    top: 0 !important;
  }
}

.fade-out.ng-hide {
  opacity: 0;
}

.fade-out.ng-hide-add,
.fade-out.ng-hide-remove {
  transition: opacity linear 0.2s;
  transition-delay: 0s;
}

.gallery {
  .item {
    padding: 0;
    display: block;
    margin: 0 5px 10px 0;
    border: 1px solid $border-color;
    text-decoration: none !important;

    .header,
    .footer {
      padding: 2px 5px;
      text-transform: capitalize;
      background-color: $panel-default-heading-bg;
      color: $gray-dark;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .header {
      border-bottom: 1px solid $border-color;
      padding: 4px 10px;
    }

    .footer {
      border-top: 1px solid $border-color;
      font-size: 12px;
      height: 23px;
    }

    &:hover,
    &:hover .header,
    &:hover .footer,
    &:focus,
    &:focus .header,
    &:focus .footer {
      border-color: darken($border-color, 5%);
      color: $gray-darker;
    }

    &:hover:not(.new),
    &:focus:not(.new) {
      transform: scale(0.99);
    }

    &:active:not(.new) {
      transform: scale(0.96);
    }

    &:focus {
      position: relative;
      top: 1px;
      left: 1px;
    }

    .body {
      text-align: center;
      padding: 10px;
      height: 108px;
      background: $panel-bg;
    }

    &.new {
      height: 160px;
      background: $gray-light;
      line-height: 150px;
      cursor: default;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      outline: none;
      border: 2px solid darken($gray-light, 5%);
      margin: 0 5px 7px 0;
    }

    &.new:hover {
      outline: none !important;
    }

    .body img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.xompass {
  font-family: 'sansation', Helvetica, Arial, sans-serif;
  text-transform: lowercase;
}

.center-middle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

asset-icon {
  display: inline-block;
  text-align: center;

  > span {
    display: inline-block;

    img {
      vertical-align: text-top;
    }
  }
}

.noti-dot {
  position: relative;
  display: inline-block;
  padding-right: 9px;

  .fa-circle {
    position: absolute;
    border-radius: 50%;
    border: 1px solid #fff;
    top: 1px;
    right: 1px;
    font-size: 10px;
  }
}

#text-tester {
  top: -5000px;
  position: fixed;
}

.bg-image-container {
  aspect-ratio: 16/9;
  position: relative;
  .img-target {
    img,
    canvas {
      width: 100%;
      max-height: 100%;
    }
  }
}

.bg-video-container {
  aspect-ratio: 16/9;
  position: relative;
  max-height: 100%;
  margin: auto;

  .video-target {
    video {
      display: block;
      width: 100%;
    }
  }
}
