.profile-content {
  &:after {
    content: '';
    clear: both;
    display: table;
  }

  .profile-left {
    width: 160px;
    float: left;

    img {
      width: 150px;
      height: 150px;
    }
  }

  .profile-right {
    margin-left: 170px;

    .table {
      > thead h3 {
        small {
          font-size: 60%;
        }
      }

      > tbody > tr > td {
        font-size: 13px;

        &.field {
          width: 150px;
          text-align: left;
          font-weight: 600;
          color: $gray;
        }
      }
    }
  }

  .table-responsive {
    border: none;

    .button-2fa {
      background-color: $brand-success;
    }
  }
}

.profile-menu {
  margin-left: -$well-padding;
  margin-right: -$well-padding;

  .list-group-item {
    border-left: none;
    border-right: none;

    .fas {
      color: #999;
      font-size: 11px;
      width: 20px;
      text-align: center;
      line-height: 17px;

      &.fas-caret-right {
        font-size: 13px;
        text-align: right;
      }
    }

    &.active {
      background: $gray;
      border-color: $gray;

      .fas {
        color: $gray-lighter;
      }
    }
  }
}

.permission-table {
  > thead,
  > tbody {
    > tr {
      > td,
      > th {
        padding: 12px;
      }
    }
  }

  > thead th {
    background: $body-bg;
    border-bottom-width: 1px !important;

    h4 {
      margin: 0 0 5px;
      font-size: $font-size-base * 1.1;
      font-weight: bold;
    }
  }

  .type-column {
    text-align: center;
    width: 100px;

    > span {
      display: block;
    }
  }

  td.checkbox-cell {
    text-align: center;
  }

  .btn {
    display: block;

    &.checkbox {
      margin: 0;
    }
  }

  td.disabled {
  }
}

@media (min-width: 600px) and (max-width: 760px) {
  .profile-content {
    .profile-left {
      .profile-left-fix {
        max-width: 188px;
        margin: auto;
      }
    }
  }
}

@media (max-width: 760px) {
  .profile-content {
    .profile-left {
      width: auto;
      float: none;
    }

    .profile-right {
      margin-left: 0;
    }
  }
}
