$wz-color-default: #e6e6e6;
$wz-color-current: lighten(#0089ab, 2%);
$wz-color-done: darken($gray, 15%);
$wz-color-editing: #ff0000;

.wizard {
  position: relative;
  padding: 66px 15px 15px;
  background: #fff;
  border: 1px solid $border-color;

  h2 {
    font-size: 21px;
    margin: 20px 0 20px 0;
    border-bottom: 1px solid $border-color;
    line-height: 1.42857;
  }

  .step-buttons {
    position: absolute;
    right: 10px;
    top: 66px;
    margin-top: 8px;

    .btn {
      min-width: 100px;
    }
  }

  .steps-indicator {
    right: 0;
    left: 0;
    margin: 0;
    padding: 20px 0 0 0;
    list-style: none;
    position: absolute;
    top: 0;
    border-bottom: 1px solid $border-color;
    background: $panel-default-heading-bg;

    &:before {
      background-color: darken($gray, 10%);
      content: "";
      position: absolute;
      height: 3px;
    }

    /* --- http://www.paulirish.com/2012/box-sizing-border-box-ftw/ ---- */
    * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    li {
      position: relative;
      float: left;
      margin: 0;
      padding: 15px 0;
      text-align: center;
      line-height: 15px;

      a {
        color: $gray;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        transition: 0.25s;
        cursor: pointer;

        &:hover {
          color: darken($wz-color-current, 20%);
        }

        &:before {
          position: absolute;
          top: -8px;
          left: calc(50% - 9px);
          width: 18px;
          height: 18px;
          border-radius: 100%;
          background-color: darken($gray, 10%);
          content: "";
          transition: 0.25s;
          border: 2px solid transparent;
        }
      }
    }

    li.default {
      pointer-events: none;

      a:hover {
        color: $wz-color-current;
      }
    }

    &.steps-2 {
      &:before {
        left: calc(100% / 2 / 2);
        right: calc(100% / 2 / 2);
      }

      li {
        width: calc(100% / 2);
      }
    }

    &.steps-3 {
      &:before {
        left: calc(100% / 3 / 2);
        right: calc(100% / 3 / 2);
      }

      li {
        width: calc(100% / 3);
      }
    }

    &.steps-4 {
      &:before {
        left: calc(100% / 4 / 2);
        right: calc(100% / 4 / 2);
      }

      li {
        width: calc(100% / 4);
      }
    }

    &.steps-5 {
      &:before {
        left: calc(100% / 5 / 2);
        right: calc(100% / 5 / 2);
      }

      li {
        width: calc(100% / 5);
      }
    }

    &.steps-6 {
      &:before {
        left: calc(100% / 6 / 2);
        right: calc(100% / 6 / 2);
      }

      li {
        width: calc(100% / 6);
      }
    }

    &.steps-7 {
      &:before {
        left: calc(100% / 7 / 2);
        right: calc(100% / 7 / 2);
      }

      li {
        width: calc(100% / 7);
      }
    }

    &.steps-8 {
      &:before {
        left: calc(100% / 8 / 2);
        right: calc(100% / 8 / 2);
      }

      li {
        width: calc(100% / 8);
      }
    }

    &.steps-9 {
      &:before {
        left: calc(100% / 9 / 2);
        right: calc(100% / 9 / 2);
      }

      li {
        width: calc(100% / 9);
      }
    }

    &.steps-10 {
      &:before {
        left: calc(100% / 10 / 2);
        right: calc(100% / 10 / 2);
      }

      li {
        width: calc(100% / 10);
      }
    }

    li.current,
    li.editing {
      pointer-events: none;
    }

    li.current {
      a:before {
        background-color: $wz-color-current;
      }
    }

    li.done {
      a:before {
        background-color: $wz-color-done;
      }
    }

    li.editing {
      a:before {
        background-color: $wz-color-current;
      }
    }
  }

  &.ssd {
    .steps-indicator {
      background: darken($alert-danger-bg, 40%);

      &:before {
        background-color: #fff;
      }

      li {
        a {
          color: #fff;
          &:before {
            border-color: #fff;
          }
        }
      }
    }

    &.testing {
      .steps-indicator {
        background: #2e363f;
      }
    }
  }
}

.wizard-single-step {
   .steps-indicator {
     margin: 0;
     @extend .modal-header;
     height: auto;

     li {
       display: none;

       a {
         color: #ffffff;
       }

       &.current,
       &.editing {
         display: block;
       }
     }
   }

   .step {
     @extend .modal-body;
   }
}
