/* style for visavail.js */
.visavail {
  position: relative;
  p {
    margin: 0;
  }

  .rect_has_data {
    fill: $brand-success;
    &:hover {
      fill: darken($brand-success, 10%);
    }
  }
  .rect_has_no_data {
    fill: $brand-danger;
    &:hover {
      fill: darken($brand-danger, 10%);
    }
  }
  .x_tick_emph {
    font-weight: bold;
  }
  .ytitle {
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    overflow: hidden;
    fill: $text-color;
  }
  .ytitle_icon_image {
    display: none;
  }
  .ytitle_icon_background {
    display: none;
  }
  .ytitle.link {
    cursor: pointer;
    fill: #07c;
  }
  .ypercentage {
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    overflow: hidden;
    fill: $text-color;
  }
  .xAxis {
    path {
      display: none;
    }
    line {
      display: none;
    }
    text {
      font-size: 12px;

      -moz-osx-font-smoothing: grayscale;
      fill: $text-muted;
    }
  }
  .vert_grid {
    fill: none;
    stroke: #dddddd;
    stroke-width: 1px;
  }
  .vert_grid_emph {
    fill: none;
    stroke: #dddddd;
    stroke-width: 2px;
  }
  .horz_grid {
    fill: none;
    stroke: #dddddd;
    stroke-width: 1px;
  }
  .heading {
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bold;
  }
  .subheading {
    font-size: 12px;
    -moz-osx-font-smoothing: grayscale;
    fill: #777;
  }
  .legend {
    font-size: 12px;
    -moz-osx-font-smoothing: grayscale;
    fill: #777;
  }
  .subchart-xAxis {
    path {
      color: #777;
    }
    line {
      color: #777;
    }
    text {
      -moz-osx-font-smoothing: grayscale;
      fill: #777;
    }
  }
}
.visavail-tooltip {
  div.tooltip-top {
    position: absolute;
    text-align: left;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    padding-left: 0;
    width: auto;
    border: 0;
    pointer-events: none;
    line-height: 12px;
    padding-top: 0;
    display: block;
  }
  div.tooltip-overlay {
    position: absolute;
    text-align: left;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    min-width: 120px;
    width: auto;
    border: 0;
    pointer-events: none;
    line-height: 16px;
    background: #f2f2f2;
    padding: 10px;
    border-radius: 4px;
  }
  .tooltip_has_data {
    color: #449d44;
  }
  .tooltip_has_no_data {
    color: #c9302c;
  }
}
.visavail-ytitle-tooltip {
  div.y-tooltip {
    position: absolute;
    text-align: left;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    width: 190px;
    border: 0;
    pointer-events: none;
    background: #5c5c5c;
    padding: 10px;
    color: #ffffff;
  }
}
div.y-tooltip.right {
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent #5c5c5c transparent transparent;
  }
}
div.y-tooltip.top {
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0%;
    margin-top: 0px;
    border-width: 7px;
    border-style: solid;
    border-color: #5c5c5c transparent transparent transparent;
  }
}
div.y-tooltip.bottom {
  &::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    margin-top: -14px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #5c5c5c transparent;
  }
}
