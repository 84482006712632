@keyframes text-red-white-animation {
  0% {
    color: #DC281E;
  }
  33% {
    color: lighten(#DC281E, 50%);
  }
  66% {
    color: #fff;
  }
  100% {
    color: lighten(#DC281E, 50%);
  }
}

.text-red-white {
  -webkit-animation: text-red-white-animation .8s 3 linear;
  animation: text-red-white-animation .8s 3 linear;
}
