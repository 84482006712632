.resizable-content {
  @extend .absolute;

  > div {
    @extend .absolute;

    overflow: auto;

    &.resize-handler {
      background: $gray-lighter;
    }
  }

  &.vertical {
    > div {
      &.left {
        right: auto;
        width: calc(50% - 4px);
      }

      &.right {
        left: calc(50% + 4px);
      }

      &.resize-handler {
        left: calc(50% - 4px);
        right: auto;
        width: 8px;
        cursor: e-resize;

        &:before {
          font-size: 12px;
          content: '∥';
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          text-align: center;
        }
      }
    }
  }

  &.horizontal {
    > div {
      &.top {
        bottom: calc(50% - 4px);
      }

      &.bottom {
        top: auto;
        height: calc(50% - 4px);
      }

      &.resize-handler {
        bottom: calc(50% - 4px);
        top: auto;
        height: 8px;
        cursor: n-resize;

        &:before {
          font-size: 12px;
          content: '=';
          position: absolute;
          left: 50%;
          top: 0;
          bottom: 0;
          line-height: 10px;
        }
      }
    }
  }
}
