/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
// Core variables and mixins
@import '../variables-dark';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins';
// Reset and dependencies
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print';
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";
// Core CSS
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons';
// Components
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination';
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close';
// Components w/ JavaScript
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers';
// @import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';
@import 'bootstrap_ms';
@import 'bs-breadcrumb';
@import 'bs-buttons';
@import 'bs-forms';
@import 'bs-modal';
@import 'bs-navbar';
@import 'bs-panels';
@import 'bs-progress-bar';
@import 'bs-tables';
@import 'bs-tabs';
@import 'ui/tooltip';
@import 'ui/datepicker';
@import 'ui/accordion';
@import 'bs-five-columns';
@import 'bs-flex-columns';

.row.no-space {
  margin-left: 0;
  margin-right: 0;

  .col-lg-1,
  .col-md-1,
  .col-sm-1,
  .col-ms-1,
  .col-xs-1,
  .col-lg-2,
  .col-md-2,
  .col-sm-2,
  .col-ms-2,
  .col-xs-2,
  .col-lg-3,
  .col-md-3,
  .col-sm-3,
  .col-ms-3,
  .col-xs-3,
  .col-lg-4,
  .col-md-4,
  .col-sm-4,
  .col-ms-4,
  .col-xs-4,
  .col-lg-5,
  .col-md-5,
  .col-sm-5,
  .col-ms-5,
  .col-xs-5,
  .col-lg-6,
  .col-md-6,
  .col-sm-6,
  .col-ms-6,
  .col-xs-6,
  .col-lg-7,
  .col-md-7,
  .col-sm-7,
  .col-ms-7,
  .col-xs-7,
  .col-lg-8,
  .col-md-8,
  .col-sm-8,
  .col-ms-8,
  .col-xs-8,
  .col-lg-9,
  .col-md-9,
  .col-sm-9,
  .col-ms-9,
  .col-xs-9,
  .col-lg-10,
  .col-md-10,
  .col-sm-10,
  .col-ms-10,
  .col-xs-10,
  .col-lg-11,
  .col-md-11,
  .col-sm-11,
  .col-ms-11,
  .col-xs-11,
  .col-lg-12,
  .col-md-12,
  .col-sm-12,
  .col-ms-12,
  .col-xs-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.row.row-2x {
  margin-left: -3 * $spacing;
  margin-right: -3 * $spacing;

  .col-lg-1,
  .col-md-1,
  .col-sm-1,
  .col-ms-1,
  .col-xs-1,
  .col-lg-2,
  .col-md-2,
  .col-sm-2,
  .col-ms-2,
  .col-xs-2,
  .col-lg-3,
  .col-md-3,
  .col-sm-3,
  .col-ms-3,
  .col-xs-3,
  .col-lg-4,
  .col-md-4,
  .col-sm-4,
  .col-ms-4,
  .col-xs-4,
  .col-lg-5,
  .col-md-5,
  .col-sm-5,
  .col-ms-5,
  .col-xs-5,
  .col-lg-6,
  .col-md-6,
  .col-sm-6,
  .col-ms-6,
  .col-xs-6,
  .col-lg-7,
  .col-md-7,
  .col-sm-7,
  .col-ms-7,
  .col-xs-7,
  .col-lg-8,
  .col-md-8,
  .col-sm-8,
  .col-ms-8,
  .col-xs-8,
  .col-lg-9,
  .col-md-9,
  .col-sm-9,
  .col-ms-9,
  .col-xs-9,
  .col-lg-10,
  .col-md-10,
  .col-sm-10,
  .col-ms-10,
  .col-xs-10,
  .col-lg-11,
  .col-md-11,
  .col-sm-11,
  .col-ms-11,
  .col-xs-11,
  .col-lg-12,
  .col-md-12,
  .col-sm-12,
  .col-ms-12,
  .col-xs-12 {
    padding-left: 3 * $spacing !important;
    padding-right: 3 * $spacing !important;
  }
}

.page-header {
  background-color: lighten($navbar-inverse-bg, 3%);
  margin: 0 0 4 * $spacing 0;
  border-bottom: 1px solid $border-color;
  padding: 0 $grid-gutter-width/2;
  min-height: 50px;

  h1 {
    font-size: 27px;
    font-weight: 300;
    padding: 10px 0;
    border-bottom: 1px solid $border-color;

    > .header-text {
      padding-left: 10px;
      border-left: 2px solid lighten($gray, 10%);
      display: inline-block;
    }
  }

  .toolbars {
    &.pull-right:last-child {
      margin-right: -5px;
    }

    &.pull-left:first-child {
      margin-left: -5px;
    }
  }

  .toolbar {
    border-right: 1px solid $border-color;
    display: inline-block;
    padding: 0 10px;
    vertical-align: bottom;

    > a {
      display: block;
      margin: 0 -10px;
      padding: 0 10px;
      text-decoration: none;

      &:hover {
        background: $gray-lightest;
      }
    }
  }

  .nav-tabs {
    background: transparent;
    border: none;

    li {
      a {
        padding: 9px 10px !important;
        line-height: 28px;
        font-size: 20px !important;
        font-weight: 300 !important;
        border: none !important;
        border-bottom: 4px solid transparent !important;
      }

      &.active {
        a {
          border-color: $state-info-text !important;

          &:focus,
          &:hover,
          &:focus {
            border: none;
            border-bottom: 4px solid $state-info-text !important;
          }
        }

        &.bg-warning {
          a {
            background: darken($alert-warning-bg, 5%) !important;
          }
        }
      }

      &.bg-warning {
        a {
          &:focus,
          &:hover {
            background: darken($alert-warning-bg, 5%) !important;
          }
        }
      }
    }

    &.nav-tabs-sm {
      li {
        a {
          padding: 5px 10px !important;
          line-height: 25px;
          font-size: 18px !important;
        }
      }
    }
  }

  &.page-header-sm {
    min-height: 40px;
    line-height: 39px;

    > {
      h1,
      h2,
      h3,
      h4 {
        line-height: 39px;
      }
    }

    h1 {
      font-size: 22px;
      padding: 5px 0;
    }
  }

  &.page-header-lg {
    min-height: 80px;
  }
}

.nav-tabs {
  > li.tabs-sm {
    a {
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }
  }
}

@media (max-width: $screen-ms-max) {
  .nav-tabs li {
    width: auto !important;
  }
}

.well {
  position: relative;
  padding: $well-padding;
  box-shadow: none;

  .well-title {
    border-bottom: 1px solid $well-border;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    margin: 15px -15px;
    border-top: 1px solid $well-border;

    padding: 15px 15px 15px;
    max-width: none;

    &.well-title-inverse {
      background: $navbar-inverse-bg;
      color: #fff;
    }

    &.text-normal {
      text-transform: none;
      font-weight: normal;
    }

    .btn {
      text-transform: none;
    }

    .title-badge {
      position: absolute;
      border-radius: 50%;
      left: 15px;
      top: 12px;
      line-height: 25px;
      font-size: 90%;
      text-align: center;
      width: 30px;
      height: 30px;
    }

    .title-badge + span {
      display: block;
      padding-left: 35px;
    }

    &.success {
      background: $brand-success;
      color: #fff;
    }

    > .btn-toolbar,
    > .btn-group {
      margin-top: -7px;
    }
  }

  > .well-title:first-child {
    margin: -15px -15px 15px;
    border-top: 0;
  }

  .attached-border + .well-title:not(:first-child) {
    border-top: 0;
  }

  .attached-border {
    margin: -15px;
  }

  &.well-sm {
    margin-bottom: 9px;
    padding: 9px;

    .well-title {
      padding: 9px 9px 9px;
      margin: 9px -9px;

      .title-badge {
        left: 9px;
        top: 9px;
        line-height: 21px;
        width: 25px;
        height: 25px;
      }

      .title-badge + span {
        padding-left: 30px;
      }

      .well-toolbar {
        margin-top: -3px;
        float: right;

        .btn {
          padding: 1px 4px;
          font-size: 12px;
          line-height: 1.5;
        }
      }

      &:not(:first-child) {
        margin-top: 9px;
      }
    }

    > .well-title:first-child {
      margin-top: -9px;
    }

    .attached-border {
      margin: -9px;
    }
  }

  &.no-padding {
    .well-title {
      margin: 0 !important;
    }
  }

  &.no-padding,
  .attached-border {
    > .table {
      border-left: none;
      border-right: none;

      tr {
        td:first-child,
        th:first-child {
          border-left: none;
        }

        td:last-child,
        th:last-child {
          border-right: none;
        }
      }
    }
  }

  &.no-border {
    border-color: transparent;

    .well-title {
      border-color: transparent;
    }
  }

  &.well-light {
    background: $panel-bg;
  }
}

.form-full {
  label {
    display: block;

    .help {
      font-size: 80%;
      color: $gray;
      font-weight: normal;
      float: right;
    }
  }
}

.breadcrumb {
  @extend .nowrap;
  border-bottom: 1px solid $border-color;
  white-space: nowrap;
}

.list-group.list-group-sm {
  .list-group-item {
    padding: 7px 10px;
    font-size: 0.9em;
  }
}

.alert.alert-default {
  border-color: $border-color;
}

.container-lg {
  @extend .container;
}

@media (min-width: 1400px) {
  .container-lg {
    width: 1200px;
  }
}

@media (max-width: $screen-xs-max) {
  .static-xs {
    position: static !important;
    height: auto;

    &.panel.absolute {
      .panel-body {
        position: static !important;
      }
    }
  }
}

@media (max-width: $screen-ms-max) {
  .static-ms {
    position: static !important;
    height: auto;

    &.panel.absolute {
      .panel-body {
        position: static !important;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .static-sm {
    position: static !important;
    height: auto;

    &.panel.absolute {
      .panel-body {
        position: static !important;
      }
    }
  }
}

.label {
  &.label-offline,
  &.label-OFFLINE {
    color: $brand-danger;
    border: 1px solid $brand-danger;
  }

  &.label-online,
  &.label-ONLINE {
    color: $brand-success;
    border: 1px solid $brand-success;
  }

  &.label-outdated,
  &.label-OUTDATED {
    color: darken($brand-warning, 10%);
    border: 1px solid $brand-warning;
  }

  &.label-undefined,
  &.label-unknown,
  &.label-UNKNOWN {
    color: $gray;
    border: 1px solid $gray;
  }

  &.label-primary {
    color: $brand-primary;
    border: 1px solid $brand-primary;
    background: transparent;
  }

  &.label-sm {
    font-size: 65%;
    padding: 0.1em 0.4em 0.2em;
  }
}

.status-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;

  &.status-error {
    background: $brand-danger;
  }

  &.status-warning {
    background: $brand-warning;
  }

  &.status-unknown {
    background: $gray;
  }

  &.status-success {
    background: $brand-success;
  }
}
