.kpi {
  background: $panel-bg;
  padding: 5px;
  border: 1px solid $border-color;
  margin-bottom: 10px;
  position: relative;
  border-radius: 5px;

  .title-kpi {
    @extend .nowrap;

    display: block;
    text-align: center;
    font-size: 18px;
    color: $gray;
    position: relative;
  }

  .value-kpi {
    display: block;
    text-align: center;
    font-size: 25px;
    color: $gray-darker;
    border-bottom: 1px solid $border-color;
  }

  .rate-kpi {
    margin-top: 15px;
    display: block;
    text-align: center;
    font-size: 20px;
    color: #6a8f64;
  }

  .rate-kpi-type2 {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #b3091a;
  }

  &.kpi-primary {
    background: $brand-primary;
    color: #fff;

    .title-kpi,
    .value-kpi {
      color: #fff;
    }

    .value-kpi {
      border-bottom: 1px solid darken($brand-primary, 10%);
    }
  }

  &.kpi-basic {
    margin: 0;
    border: 0;
    background: transparent;

    .value-kpi {
      border-bottom: 0;
    }

    .rate-kpi {
      margin-top: 0;
      line-height: 20px;
      font-size: 18px;
    }

    & + .kpi-basic {
      border-left: 1px solid $border-color;
    }
  }
}

.dashboard-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  right: 5px;
  overflow-y: auto;

  .dashboard-header {
    padding: 10px;
    background: $panel-bg;
    border-bottom: 1px solid $border-color;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    a {
      z-index: inherit !important;
    }
    h3 {
      font-size: 2rem;
    }
  }

  .dashboard-footer {
    background: $panel-bg;
    padding: 10px;
    border-top: 1px solid $border-color;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
  }

  .dashboard-content {
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
  }

  .dashboard-header + .dashboard-content {
    top: 50px;
  }

  .dashboard-footer + .dashboard-content {
    bottom: 50px;
  }

  .dashboard-header + .dashboard-footer + .dashboard-content {
    bottom: 50px;
    top: 50px;
  }

  detection-data-display {
    .btn {
      z-index: inherit !important;
    }
  }
}

.page-header + .dashboard-wrapper {
  top: 101px;
}

@media (max-width: $screen-ms-max) {
  .dashboard-wrapper {
    position: relative;
    min-height: 500px;
    max-height: 700px;
  }

  .page-header + .dashboard-wrapper {
    top: 0;
  }
}

.detections-history-elements-container {
  display: grid;
  width: 100%;
  grid-gap: 1rem;
  justify-content: space-evenly;
  align-content: start;
  overflow-x: hidden;
  .detections-history-element {
    width: 100%;
    height: auto;
  }
}
#detection-history-select div {
  position: relative;
  height: 100%;
}
@media (max-width: 767px) {
  .detections-history-menu {
    #responsive-filter-menu-bar {
      margin-top: 90px;
      position: absolute;
    }
  }
  .dashboard-wrapper {
    min-height: 100vh;
    height: 100%;
    .dashboard-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .dashboard-content {
      margin-top: 90px;
      padding: 10px;
    }
    .dashboard-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 90px;
    }
  }
  .detections-history-elements-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .detections-history-menu {
    #responsive-filter-menu-bar {
      margin-top: 50px;
    }
  }
}
@media (min-width: 992px) {
  .detections-history-menu {
    height: 100%;
    #responsive-filter-menu-bar {
      ul {
        list-style: none;
      }
    }
  }
}
@media (min-width: 1390px) {
  .detections-history-elements-container {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }
}
@media (min-width: 768px) and (max-width: 1389px) {
  .detections-history-elements-container {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}
