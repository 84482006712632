.sp-container {
  background-color: $dropdown-bg;
  border-color: $border-color;
}

.sp-picker-container,
.sp-palette-container {
  border-color: $border-color;
}

.sp-palette .sp-thumb-el {
  border-color: $border-color;
}

.sp-initial,
.sp-clear,
.sp-color,
.sp-hue {
  border-color: $border-color;
}

.sp-input {
  @extend .form-control, .input-sm;
  height: 27px;
}

label.color-picker-xs {
  > span {
    vertical-align: middle;
    display: inline-block;
  }
}

.color-picker-xs .sp-replacer {
  background-color: transparent;
  border: none;
  padding: 0;
}

/*.sp-clear-display{
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: 2px 1px;
    border: solid 1px #d0d0d0;
}*/

.color-picker-xs .sp-preview {
  height: 24px;
  width: 24px;
  background-image: none;
  border-color: $border-color;
  margin-right: 3px;
}

.color-picker-xs .sp-preview-inner {
  margin: 1px;
}

.color-picker-xs .sp-dd {
  display: none;
}

.input-group-btn .color-picker-xs .sp-preview {
  border-left: none;
  margin: 0;
}

.sp-button-container {
  margin-top: 4px;

  button {
    text-shadow: none;
  }

  .sp-choose,
  .sp-cancel {
    @extend .btn;
    @extend .btn-xs;
    padding: 3px 5px;
  }

  .sp-choose {
    @extend .btn-primary;

    &:hover {
      background-image: none;
      text-shadow: none;
    }
  }

  .sp-cancel {
    @extend .btn-default;
    color: $text-color !important;
  }
}

.table {
  td {
    .color-picker-xs {
      display: inline-block;
      .sp-preview {
        height: 18px;
        width: 18px;
        background-image: none;
        border-color: $border-color;
        margin-right: 0;
      }
    }
  }
}
