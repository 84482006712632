.tag {
  margin: 2px 1px !important;
  font-size: 100%;
  display: inline-block;
  max-width: 100%;
  padding: 0 !important;
  border-radius: 4px;

  > span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    vertical-align: bottom;
    text-transform: capitalize;
    padding: 5px 10px 5px 5px;
  }

  a {
    display: inline-block;
    cursor: pointer;
    padding: 0 0 0 5px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    text-align: center;
    vertical-align: middle;

    &:hover {
      opacity: 1;
    }

    .fas {
      margin-bottom: 0;
    }

    + span {
      padding-left: 0;
      max-width: 94%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .remove {
    vertical-align: bottom;
    top: 0;
  }

  &.label-primary {
    a {
      background: transparent;
      color: $brand-primary;
    }
  }

  &.label-info {
    a {
      background: transparent;
      color: $brand-info;
    }
  }

  &.tag-sm {
    border-radius: 0;
    font-size: 70%;

    > span {
      padding: 2px 4px;
    }

    > a {
      padding: 2px 0 2px 2px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      .fas {
        margin-bottom: 0;
      }

      & + span {
        padding-left: 0;
      }
    }
  }
}
