$sidebar-width: 200px;
$sidebar-right-border: 5px;
$sidebar-collapsed-width: 60px;

.navbar {
  .open {
    > a {
      &:hover,
      &:focus {
        border-color: $border-color;
      }
    }
  }
}

.navbar-top-links {
  margin-right: 0;

  .nav-button {
    font-size: 18px;
  }

  > li {
    display: inline-block;
    vertical-align: top;

    > a {
      color: $gray !important;
      padding: 5px 8px;
      min-height: $navbar-height - 1;
      min-width: $navbar-height - 1;
      text-align: center;
      transition: 0.3s;
      line-height: 30px;
    }

    > dark-mode-toggle {
      --dark-mode-toggle-icon-size: 1.7rem;
      padding: 5px 8px;
      line-height: 13px;
    }

    &.open > a,
    > a:hover,
    > a:active,
    > a:focus {
      background-color: transparent !important;
      color: $gray-darker;
    }

    &:last-child {
      margin-right: 15px;
    }
  }

  .dropdown-menu {
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border-width: 0;
    box-shadow: none;

    .divider {
      margin: 3px 0;
    }

    li {
      display: block;

      a {
        padding: 3px 20px;
        min-height: 0;
        line-height: 25px;
        margin: 4px;
        text-align: left;

        .fas,
        .fab {
          line-height: 25px;
        }

        div {
          white-space: normal;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.lang-dropdown {
      min-width: 65px;

      > li {
        padding: 0;

        > a {
          margin: 0;
          text-align: center;
        }

        &.active {
          > a {
            background: $brand-primary;
            color: #fff;
          }
        }
      }
    }
  }

  .open {
    .dropdown-menu {
      height: auto;
      margin: 0;
      border-width: 1px;
      box-shadow: 0 2px 3px rgba(86, 96, 117, 0.1);
    }

    > a {
      &:hover,
      &:focus {
        border-color: $border-color !important;
      }
    }
  }

  .dropdown-messages,
  .dropdown-tasks,
  .dropdown-alerts {
    width: 310px;
    min-width: 0;
  }

  .dropdown-messages {
    margin-left: 5px;
  }

  .dropdown-tasks {
    margin-left: -59px;
  }

  .dropdown-alerts {
    margin-left: -123px;
  }

  .dropdown-user {
    min-height: $navbar-height - 1;
    padding: 3px 8px;

    .profile-thumb img {
      margin-right: 5px;
      width: 34px;
      height: 34px;
      border-radius: 50%;
    }

    .dropdown-menu {
      max-width: 200px;
    }
  }
}

.navbar-brand {
  background: darken($navbar-inverse-bg, 5%);
  width: $sidebar-width;
  display: block;
  float: none;
  font-family: 'sansation', Helvetica, Arial, sans-serif;
  text-transform: lowercase;
  /*border-bottom: 1px solid darken($navbar-inverse-bg, 5%);*/

  /*color: #fff !important;*/
  line-height: 22px;
  overflow: hidden;
  font-size: 21px;

  padding: 8px 9px;

  .navbar-brand {
    height: 41px;
  }

  .brand-full {
    height: 25px;
    display: block;
    background-image: url('/assets/images/themes/vsaas/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .brand-cut {
    height: 25px;
    width: 25px;
    vertical-align: top;
    display: inline-block;
    background-image: url('/assets/images/themes/vsaas/logo-cut.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .brand-label {
    width: 100px;
    display: inline-block;
    vertical-align: middle;
    background-image: url('/assets/images/themes/vsaas/logo-label.png');
    height: 18px;
    margin-left: 10px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  img {
    width: 25px;
    display: inline;
    vertical-align: top;
    margin-right: 5px;
  }

  &:hover,
  &:focus {
    background: darken($navbar-inverse-bg, 5%) !important;
  }
}

.navbar-toggle .icon-bar {
  background-color: lighten($navbar-inverse-bg, 20%) !important;
}

.navbar-default .navbar-toggle-lg {
  border-color: #fff;
  border-radius: 0;
  padding: 9px 7px;

  .icon-bar {
    background-color: #fff;
  }
}

// --Sidebar

.sidebar {
  overflow-x: hidden;

  ul li {
    h4,
    h5,
    a {
      color: $navbar-inverse-color;
    }

    h4 {
      padding: 10px 0;
      background: darken($navbar-inverse-bg, 5%);
      margin: 0;

      .small {
        display: block;
        color: $state-info-text;
        font-size: 12px;
        margin-bottom: 3px;
      }
    }

    h5 {
      padding: 8px 0 8px 0;
      margin: 0;
      font-size: 16px;
      border-bottom: 1px solid darken($navbar-inverse-bg, 5%);

      .small {
        display: block;
        color: $state-info-text;
        font-size: 12px;
        margin-bottom: 3px;
      }
    }

    a {
      cursor: pointer;
      padding: 5px;
      line-height: 33px;
      transition: all 0.4s;
      display: block;
      text-decoration: none;

      .fa-circle {
        font-size: 7px !important;
        vertical-align: middle;
        opacity: 0.2;
        margin-right: 5px;
        line-height: 22px !important;
      }

      &:hover,
      &:focus {
        background: transparent;
        outline: none;
      }
    }

    h4,
    h5 {
      > a {
        padding: 0;
        line-height: 1.4em;

        &:hover,
        &:focus,
        &.active {
          background-color: transparent !important;
        }
      }
    }

    &.active {
      > a,
      > div > a {
        background: transparent;
        color: #ffffff;
      }
    }

    &.divider {
      margin: 10px 0;
      height: 1px;
      background-color: darken($navbar-inverse-bg, 10%);
      overflow: hidden;
    }

    &.customer-link {
      a {
        font-size: 13px;
        padding-left: 10px;
      }
    }
  }

  .nav > li {
    a:hover {
      background-color: darken($navbar-inverse-bg, 5%);
      color: $navbar-inverse-link-hover-color;
    }
  }

  .panel {
    border: none;
    margin: 0;
    background: transparent;
    box-shadow: none;

    .nav-second-level {
      float: none;
      border: 0;
      box-shadow: none;
      margin: 0;
      padding: 0;
      background: darken($navbar-inverse-bg, 7%);
      display: block;

      li {
        border: 0;

        a {
          padding: 5px 10px 5px 20px;

          &:focus,
          &:hover {
            background-color: transparent;
            color: $navbar-inverse-link-hover-color;
          }

          > span {
            max-width: 150px;
            display: inline-block;
            vertical-align: bottom;
            @extend .nowrap;
          }
        }

        &.active {
          > a {
            background: transparent;
            color: #ffffff;
          }
        }
      }
    }

    &.panel-open {
      > a {
        background: darken($navbar-inverse-bg, 3%) !important;
      }
    }
  }

  .sidebar-nav.navbar-collapse {
    padding-left: 0;
    padding-right: 0;

    &.collapsing {
      overflow-y: hidden;
    }
  }

  .sidebar-search {
    padding: 15px;
  }

  .arrow {
    float: right;
    padding: 10px;
  }

  .fas.arrow:before {
    content: '\f104';
  }

  .panel-open > a .fas.arrow:before {
    content: '\f107';
  }

  .nav-second-level li,
  .nav-third-level li {
    border-bottom: none !important;
  }

  .nav-second-level li a {
    padding-left: 30px;
  }

  .nav-third-level li a {
    padding-left: 52px;
  }
}

#side-menu > li > div > a,
#side-menu > li > mda-auth {
  .fab,
  .fas:not(.arrow),
  .svg-icon {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 3px;
  }
}

.navbar {
  margin-bottom: 0;
  position: static;
  border-bottom: 1px solid $navbar-default-border;
}

.navbar-form-group {
  margin: 4px 5px 0 0;
  background: $input-bg;

  .select2-container {
    .select2-choice {
      border: 1px solid $border-color;
      color: $text-color !important;
      background: transparent !important;
      box-shadow: none;
      height: $navbar-height -7;
      line-height: $navbar-height -10;

      > .select2-chosen {
        margin-right: 24px;
        width: 118px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .select2-arrow {
        // display: none;
        border: none;
        background: transparent;
        width: 24px;
      }
    }

    .ui-select-dropdown {
      width: 250px;
    }
  }

  .select2-results {
    li:not(.ui-select-choices-group) {
      padding: 0;
      margin-top: 4px;
    }

    .select2-result-label {
      padding: 0;

      a {
        padding: 9px 7px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    a {
      display: block;
    }

    .select2-highlighted {
      background: $dropdown-link-active-bg;

      * {
        color: $gray-darker;
      }
    }
  }

  .select2-results {
    padding: 0 0 0 4px;
  }

  .select2-drop {
    margin: 5px 0;
    padding: 4px 1px 0;
  }

  .select2-search input {
    height: 30px !important;
    width: 100%;
  }
}

@media (min-width: $screen-sm-min) {
  .navbar-header {
    margin-right: 5px;
  }

  .navbar-brand {
    display: none;
  }

  .navbar {
    padding-left: $sidebar-collapsed-width + 5;
  }

  .navbar-toggle-lg {
    display: block;
  }

  .sidebar {
    z-index: 1;
    position: fixed;
    width: $sidebar-width;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .system-message + div > nav-bar {
    .sidebar {
      top: 36px;
    }
  }

  .app-message + div {
    .sidebar {
      top: 34px;
      bottom: 34px;
    }
  }

  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }

  .navbar.collapsed {
    .sidebar {
      z-index: 9999;
      border-right: 2px solid darken($navbar-inverse-bg, 5%);
      transform: translateX(-($sidebar-width - $sidebar-collapsed-width)) translateY(0)
        translateZ(0);

      .navbar-brand {
        display: block;
        padding-left: 15px;
      }

      ul {
        li a,
        li mda-auth {
          padding: 5px 10px 5px 15px;
        }
      }

      & > * {
        transform: translateX(($sidebar-width - $sidebar-collapsed-width)) translateY(0)
          translateZ(0);
        overflow-x: hidden;
        max-width: 100%;
      }

      &,
      & > * {
        transition: transform 0.2s ease-out, padding-left 0.2s ease-out;
        will-change: transform;
      }

      .hide-collapsed {
        opacity: 0;
        transition: opacity 0.28s ease-out;
      }

      h4.hide-collapsed,
      h5.hide-collapsed {
        opacity: 1;

        a {
          padding: 5px 4px;
        }
      }

      .panel-open .collapse.in {
        display: none;
      }

      h4,
      h5 {
        .nav-name-label::first-letter {
          font-weight: bold;
        }
      }

      &:hover {
        ul {
          li a {
            padding-left: 10px;
          }
        }

        &,
        & > * {
          transform: translateX(0px) translateY(0) translateZ(0);
        }

        h4 {
          padding: 10px;
        }

        h5 {
          padding: 8px 10px;
        }

        .hide-collapsed {
          //display: inline-block;
          opacity: 1;
        }

        h4.hide-collapsed,
        h5.hide-collapsed {
          display: block;
        }

        .panel-open .collapse.in {
          display: block;
        }

        .navbar-brand {
          padding-left: 9px;
        }
      }
    }
  }

  .navbar.collapsed + #page-wrapper,
  nav-bar.collapsed + #page-wrapper {
    margin-left: $sidebar-collapsed-width;
  }
}

.system-message {
  .alert {
    border-radius: 0;
    padding: 7px;
  }
}

@media (max-width: $screen-sm-max) {
  .navbar-nav,
  .navbar-top-links {
    border-bottom: 1px solid $border-color;
  }

  .sidebar {
    ul li {
      h4,
      h5 {
        padding: 5px 10px;
      }
    }
  }
}

@media (max-width: $screen-ms-max) {
  .navbar-brand {
    background: $navbar-inverse-bg;
  }

  .navbar-static-top {
    border-width: 0;
  }

  .navbar-header {
    background: $navbar-inverse-bg;
  }
}
