.raphael-draw {
  svg {
    display: block;
    left: 0 !important;
    margin: auto;
  }

  #paper.drawing {
    cursor: crosshair;
  }

  #paper.close {
    cursor: pointer;
  }

  .draw-menu {
    margin: 0;
    display: none;
    width: auto;
    position: absolute;
    padding: 0;
    list-style: none;
    background: #fff;
    border: 1px solid $border-color;
    font-size: 90%;
    color: #333;

    li {
      padding: 2px 5px;

      &:hover {
        background: $btn-primary-bg;
        color: $btn-primary-color;
        cursor: pointer;
      }
    }

    &.opened {
      display: block;
    }
  }
}
