.rt-panel,
.series-panel {
  margin: 0;
  height: 100%;
  .panel-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 13px;
    padding: 5px;
    margin: 0;
    height: auto;
    overflow: hidden;
    h2 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: calc(100% - 100px);
      padding-inline: 5px;
      strong,
      small {
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 160%;
      }
    }
    .panel-toolbar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      margin-inline: 5px;
      padding: 5px;
      .btn-group {
        display: flex;
        flex-wrap: nowrap;
      }

      > .btn,
      > * > .btn {
        padding: 5px;
      }
    }
  }

  .panel-body {
    position: relative;
    padding: 0;
    border-bottom-width: 1px;
  }
}

.realtime-monitoring {
  .rt-panel {
    &.rt-panel-sm {
      .panel-body {
        aspect-ratio: 16/9;
        position: relative;

        .rt-content {
          position: absolute;
          top: 5px;
          right: 5px;
          bottom: 24px;
          left: 5px;
          overflow: auto;
        }

        .bg-image-container {
          top: 0;
          width: 100%;
          position: absolute;
          bottom: 0;
          aspect-ratio: unset;
        }

        &.with-location {
          .rt-content {
            bottom: 32px;
          }
        }

        &.with-chart {
          .bg-video-container {
            aspect-ratio: unset;
            position: absolute;
            top: 0;
            bottom: 50px;
            left: 0;
            right: 0;
          }
        }

        .rt-footer {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin-bottom: 5px;
        }

        video-activity-chart {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }

        pre {
          aspect-ratio: unset !important;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: 0;
          max-height: none;
        }
      }
    }
  }

  $max-width: 20vw;

  @media (min-width: 768px) {
    .floating-container {
      // This expression sets the maximum width of the container to the minimum between:
      // - 70% of the screen width calculated as a 16/9 ratio of the screen height.
      // - 80% of the screen width obtained directly.
      // As the maximum height of the container is a 9/16 ratio of its maximum width, this
      // also bounds the maximum height of the container by 70% of the screen height.
      max-width: calc(min(70vh * 16/ 9, 80vw));
      // This expression centers the container horizontally by setting the left value to
      // the difference between half of the screen width and half of the maximum width of the container.
      left: calc(50vw - min(70vh * 16/ 9, 80vw) / 2);
      // This expression centers the container vertically analogously.
      // 10vh is an extra margin which may be replaced according to taste.
      top: calc(50vh - min(70vh * 16/ 9, 80vw) * (9 / 16) / 2 - 10vh);
      .floating-div {
        max-width: calc(min(70vh * 16 / 9, 80vw));
      }
    }
  }
  @media (max-width: 767px) {
    .floating-container {
      // This expression sets the maximum width of the container to the minimum between:
      // - 70% of the screen width calculated as a 16/9 ratio of the screen height.
      // - 95% of the screen width obtained directly.
      // As the maximum height of the container is a 9/16 ratio of its maximum width, this
      // also bounds the maximum height of the container by 70% of the screen height.
      max-width: calc(min(70vh * 16/ 9, 95vw));
      // This expression centers the container horizontally by setting the left value to
      // the difference between half of the screen width and half of the maximum width of the container.
      left: calc(50vw - min(70vh * 16/ 9, 95vw) / 2);
      // This expression centers the container vertically analogously.
      // 10vh is an extra margin which may be replaced according to taste.
      top: calc(50vh - min(70vh * 16/ 9, 95vw) * (9 / 16) / 2 - 10vh);
      .floating-div {
        max-width: calc(min(70vh * 16 / 9, 95vw));
      }
    }
  }

  .floating-container {
    width: 100%;
    .floating-div {
      position: relative;
      z-index: 2;
      border: 1px solid $border-color;
      cursor: move;
      box-shadow: 5px 10px 5px $gray-light;
      .bg-image-container {
        .img-target div {
          max-height: 10vh;
          height: auto !important;
        }
      }
    }
  }
}

.rt-panel {
  &.rt-panel-sm {
    .panel-body {
      .rt-content {
        pre {
          aspect-ratio: 16/9;
          margin: 0;
          border-radius: 0;
          border: 0;
        }

        .rt-image-canvas {
          margin: auto;
        }
      }

      .chart-header {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        z-index: 1;
        width: 100%;
        text-align: right;
        padding: 3px 5px;
        flex-wrap: nowrap;

        .btn-group {
          max-width: 75px;
          display: flex;
          flex-wrap: nowrap;
          &.btn {
            width: 25px;
          }
        }

        & + .rt-content {
          top: 28px;
        }
      }

      .rt-footer {
        text-align: center;

        .date {
          min-height: 14px;
          line-height: 12px;
          font-size: 10px;
          color: $gray;
          font-style: italic;
        }
      }
    }
  }

  &.clean-image {
    > .panel-heading {
      display: none;
    }
    [data-html2canvas-ignore] {
      display: none !important;
    }
  }
}

.floating-btn {
  position: absolute;
  top: 0;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.gauge-panel {
  .panel-heading h2 {
    float: none;
  }

  .panel-body {
    .chart {
      position: absolute;
      top: 33px;
      right: 5px;
      bottom: 52px;
      left: 5px;
    }

    .chart-footer {
      .value {
        font-size: 18px;
        min-height: 28px;
      }
    }
  }

  .tags {
    font-size: 10px;
  }

  .panel-footer {
    min-height: 0;
  }
}

.series-panel {
  border-bottom: 0 !important;

  .indicator {
    border-left: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    height: 80px;
    text-align: center;

    .indicator-name {
      padding-top: 19px;
      font-size: 13px;
      color: $state-info-text;
    }

    .indicator-value {
      font-size: 18px;
      position: relative;
      margin-bottom: 10px;

      .date {
        position: absolute;
        font-size: 11px;
        width: 100%;
        bottom: -13px;
        color: $gray;
      }
    }

    &.main {
      height: 240px;

      .indicator-name {
        padding-top: 70px;
      }

      .indicator-value {
        font-size: 22px;
      }
    }
  }

  .panel-heading {
    display: flex;
    font-size: 12px;

    .panel-toolbar {
      font-size: 12px;
      display: flex;
      flex-wrap: nowrap;

      .form-group {
        margin: 0;
      }

      input {
        height: 22px;
        margin-top: 0 !important;
        padding: 2px 6px;
        font-size: 12px;
        line-height: 1.5;
        background: $input-bg;
      }

      .btn {
        border-radius: 0;
      }
    }
  }

  .chart {
    height: 240px;
    border-bottom: 1px solid $border-color;
  }
}

.detection-colors {
  display: flex;
  height: 8px;
  .detection-color {
    border: 1px solid $border-color;
  }
}

detection-histogram,
detection-histogram .chart {
  display: block;
  aspect-ratio: 16/8;
}

@media (max-width: $screen-lg-min) {
  .series-panel {
    .panel-heading {
      height: 50px;

      .panel-toolbars,
      .panel-toolbar {
        float: none !important;
        display: block;
        width: 100%;
        border: none;
      }

      text-align: center;

      h2 {
        float: none;
        border-bottom: 1px solid $border-color;
        margin: 0;
        padding: 0;
        text-overflow: ellipsis;
      }

      .date-selector {
        float: none;
      }
    }

    .indicator,
    .indicator.main {
      height: 70px;

      .indicator-name {
        padding-top: 5px;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .series-panel {
    .indicator {
      height: 50px;

      .indicator-name {
        padding-top: 5px;
        font-size: 12px;
        font-weight: bold;
      }

      &.main {
        height: 150px;

        .indicator-name {
          padding-top: 40px;
        }
      }
    }
  }
}

@media (max-width: $screen-xs) {
  .series-panel {
    .indicator {
      height: 50px;

      .indicator-name {
        padding-top: 0;
        line-height: 50px;
        width: 70px;
        float: left;
      }

      .indicator-value {
        line-height: 50px;
        padding-left: 70px;
      }
    }
  }
}

type-value-display,
number-gauge {
  display: block;
}

.event-indicator {
  border: 2px solid $border-color;
  -webkit-box-shadow: 2px 2px 6px 0 darken($border-color, 25%);
  -moz-box-shadow: 2px 2px 6px 0 darken($border-color, 25%);
  box-shadow: 2px 2px 6px 0 darken($border-color, 25%);
  position: relative;
  background-color: $panel-bg;
  border-radius: 5px;
  height: 100px;
  margin-bottom: 15px;

  &.no-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .icon-wrapper {
    text-align: center;
    line-height: 98px;
    font-size: 28px;
    width: 60px;
    height: 97px;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    border-bottom-left-radius: 4px;
  }

  .content-wrapper {
    text-align: center;
    padding: 8px;
    background: $panel-bg;
    border-radius: 4px;

    .event-name {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .event-value {
      @extend .nowrap;
      margin-top: 5px;
      font-size: 29px;
      color: $gray-darker;
    }
  }

  .icon-wrapper + .content-wrapper {
    padding-left: 70px;
  }

  &.primary {
    border-color: $brand-primary;

    .event-name {
      color: $brand-primary;
    }

    .icon-wrapper {
      background-color: $brand-primary;
    }
  }

  &.success {
    border-color: $brand-success;

    .event-name {
      color: $brand-success;
    }

    .icon-wrapper {
      background-color: $brand-success;
    }
  }

  &.info {
    border-color: $brand-info;

    .event-name {
      color: $brand-info;
    }

    .icon-wrapper {
      background-color: $brand-info;
    }
  }

  &.default {
    border-color: $gray;

    .event-name {
      color: $gray;
    }

    .icon-wrapper {
      background-color: $gray;
    }
  }

  &.warning {
    border-color: $brand-warning;

    .event-name {
      color: $brand-warning;
    }

    .icon-wrapper {
      background-color: $brand-warning;
    }
  }

  &.danger {
    border-color: $brand-danger;

    .icon-wrapper {
      background-color: $brand-danger;
    }

    .event-name {
      color: $brand-danger;
    }
  }

  &.secondary {
    border-color: $brand-secondary;

    .icon-wrapper {
      background: $brand-secondary;
    }

    .event-name {
      color: $brand-secondary;
    }
  }
}

.event-header-indicator {
  .content-wrapper {
    text-align: center;
    padding: 0 10px;

    .event-name {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .event-value {
      @extend .nowrap;
      font-size: 26px;
      color: $gray-darker;
    }
  }
}

.event-header-indicator-wrapper {
  display: table;

  .event-header-indicator-row {
    display: table-row;

    .event-header-indicator {
      display: table-cell;
      width: 1%;

      & + .event-header-indicator {
        border-left: 1px solid $border-color;
      }
    }
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .event-indicator {
    height: auto;

    .icon-wrapper {
      position: static;
      font-size: 18px;
      width: auto;
      height: auto;
      line-height: 1.1em;
      padding: 5px;
    }

    .content-wrapper {
      padding: 5px;

      .event-name {
        line-height: 1.2em;
      }

      .event-value {
        font-size: 22px;
        line-height: 1.2em;
        margin-top: 0;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .event-indicator {
    height: 70px;

    .icon-wrapper {
      line-height: 66px;
      font-size: 20px;
      width: 45px;
      height: 66px;
    }

    .content-wrapper {
      padding: 5px 5px 5px 5px;

      .event-name {
        line-height: 1.2em;
      }

      .event-value {
        font-size: 22px;
        line-height: 1.2em;
        margin-top: 0;
      }
    }

    .icon-wrapper + .content-wrapper {
      padding-left: 50px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .event-indicator {
    height: 55px;

    .icon-wrapper {
      line-height: 51px;
      height: 51px;
    }

    .content-wrapper {
      .event-name {
        > div {
          display: inline-block;
          vertical-align: text-bottom;

          & + div {
            margin-left: 0.5rem;
          }
        }
      }
    }

    .icon-wrapper + .content-wrapper {
      margin-left: 0.3em;
    }
  }
}

.real-time-menu {
  .menu-title-container {
    background-color: $panel-default-heading-bg;
    padding: 10px;
    padding-inline: 15px;
    border-bottom: 1px solid $border-color;
    align-items: center;
    justify-content: space-between;
  }
  .menu-content-container {
    padding: 15px;
  }
}
@media (min-width: $screen-sm-min) {
  #realtime-toggle-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 15px;
    background-color: $panel-default-heading-bg;
    border-bottom: 1px solid $border-color;
    .realtime-toggle-button-label {
      margin-inline-start: 3px;
    }
  }
  .real-time-menu {
    max-height: none;
    .menu-title-container {
      display: flex;
      position: fixed;
      z-index: 1;
      width: 300px;
    }
    .menu-title-container-mobile {
      display: none;
    }
    .menu-content-container {
      padding-top: 60px;
    }
  }
  .realtime-message-container {
    min-height: 90vh;
  }
  .realtime-container {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }
  .show-sidebar {
    animation: ease-in 0.5s 1 forwards show_sidebar;
    width: 100%;
  }

  .hide-sidebar {
    animation: 0.5s ease-in-out 0.5s 1 forwards hide_sidebar;
    width: calc(100% - 300px);
  }
}

@media (max-width: $screen-sm-max) {
  #realtime-toggle-button {
    display: none;
  }
  .real-time-menu {
    max-height: 60vh;
    overflow-y: auto;
    .menu-title-container {
      position: relative;
      display: none;
      width: 100%;
    }
    .menu-title-container-mobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 0 15px 5px 15px;
      span {
        padding-inline: 5px;
        font-weight: 600;
      }
    }
    .menu-content-container {
      padding-top: 5px;
    }
  }
  .realtime-message-container {
    min-height: 60vh;
  }
  .realtime-container {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }
  .realtime-wrapper {
    width: 100%;
  }
  .show-sidebar {
    animation: none;
    width: 100%;
  }

  .hide-sidebar {
    animation: none;
    width: 100%;
  }
}

.realtime-wrapper {
  display: flex;
  align-self: flex-start;
  justify-self: flex-start;
  align-items: center;
  justify-content: center;

  .realtime-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    h1,
    h2 {
      text-align: center;
      font-size: clamp(2rem, 2.5vw, 4rem);
    }
  }

  .realtime-body-container {
    padding: 15px;
    width: 100%;
    max-height: calc(100vh - 92px);
    overflow-y: auto;
    box-sizing: border-box;
  }
}
.realtime-container {
  display: grid;
  width: 100%;
  grid-gap: 1rem;
  justify-content: space-evenly;
  align-content: start;
}

.hide-btn {
  display: flex;
  visibility: hidden;
  padding: 8px;
}

@keyframes show_sidebar {
  0% {
    width: 100%;
    overflow: visible;
    position: absolute;
  }
  100% {
    position: relative;
    width: calc(100% - 300px);
  }
}

@keyframes hide_sidebar {
  0% {
    width: calc(100% - 300px);
  }
  100% {
    width: 100%;
  }
}

.not-data-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 400;
  color: #7a8d8f;
}

.rt-content pre#raw-data {
  min-height: 90%;
  box-sizing: border-box;
  //max-height: calc(600px / 1.77);
}

.full-height {
  height: 100%;
}
