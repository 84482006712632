contextualization-asset-form {
  display: block;
}

.breadcrumb {
  //float: left;
  margin-bottom: 0;
}

// in-search-bar
.new-group-btn {
  border: none;
  vertical-align: center;
  //display: block;
  min-width: 100%;
  background-color: $btn-info-bg;
  border-radius: 10px;
  //padding: -5px;
  span {
    color: white;
  }
}

.toggler {
  color: #a1a1a4;
  font-size: 1.25em;
  margin-left: 8px;
  text-align: center;
  cursor: pointer;
}

.toggler.active {
  color: #000;
}

.grid_list_view {
  margin-top: 10px;

  .group {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .group-item {
    position: relative;
    display: block;
    margin-bottom: 10px;
    border-radius: 1px;
    background: $table-bg;
    border: 1px solid $border-color;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    padding: 0;
    font-size: 13px;

    &:hover {
      background: $table-bg-hover !important;
    }
  }

  .item-name {
    font-weight: 400;
    display: inline-block;
  }

  .item-state .item-on-off {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    overflow: hidden;
    border-radius: 50%;
    padding: 0;
    margin: 0 2px;
    background: #f2f2f2;
    text-indent: -9999px;
    color: transparent;
    line-height: 16px;
    border: 2px solid white;
  }

  .item-progress {
    .progress {
      height: 4px;
    }
  }

  .progress {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
  }

  .list {
    margin: 0 -10px;

    .group-item {
      line-height: 30px;
      display: table;
      border-collapse: collapse;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      width: 100%;
      margin-bottom: 0;
      box-shadow: none;

      &:nth-child(even) {
        background: $table-bg-accent;
      }

      > a {
        display: table;
        width: 100%;
        text-decoration: none;

        > * {
          display: table-cell;
          width: 120px;
          padding: 5px 10px;
          margin: 0;

          > .nowrap {
            width: 90px;
          }

          > * {
            margin: 0 !important;
          }
        }
      }

      .item-name {
        width: auto;
      }

      .item-icon {
        width: 40px;
        padding: 0;
        text-align: center;

        > .item-list-icon {
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }

      .checkbox {
        width: 34px;
        text-align: center;
      }

      .item-date {
        white-space: nowrap;
        overflow: hidden;
      }

      .item-completion {
        float: right;
      }
    }

    .list-only {
      display: table-cell;
    }

    .grid-only {
      display: none !important;
    }
  }

  .grid {
    text-align: center;

    .grid-only {
      display: block;
    }

    .list-only {
      display: none !important;
    }

    .group-item {
      position: relative;
      display: inline-block;
      vertical-align: top;
      height: 120px;
      width: 150px;
      margin: 0 10px 10px 0;

      a {
        display: block;
        text-decoration: none;
      }

      .item-name {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
        line-height: 25px;
        padding: 0 5px;
      }

      .item-icon {
        width: 60px;
        margin: auto;
        padding: 0;
        text-align: center;

        .item-grid-icon {
          width: 100%;
        }
      }

      .item-progress {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 2px 8px 5px;
        border-top: 1px solid #eee;
        line-height: 1em;
      }

      .item-completion-labels {
        position: absolute;
        right: 20px;
        top: 0;
        line-height: 40px;
      }

      .item-completion {
        float: right;
        line-height: 42px;
        font-size: 13px;
        vertical-align: middle;
      }

      .item-state {
        position: absolute;
        top: 5px;
        right: 5px;
      }

      .item-state .item-on-off {
        display: block;
      }

      .item-date {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 550px) {
    .grid .group-item {
      display: block;
      width: auto;
      height: 150px;
      margin: 10px auto;
    }
  }

  @media (max-width: 200px) {
    .grid .item-completion-labels {
      right: auto;
      left: 10px;
    }
  }

  .item-list-icon {
    width: 30px;
    height: 30px;
    vertical-align: bottom;
  }
}

.asset-profile {
  background: $panel-bg;
  position: relative;
  border: 1px solid $border-color;
  margin-bottom: 10px;

  .asset-profile-left {
    width: 240px;
    position: absolute;
    padding: 10px;
    left: 0;
    top: 0;

    .thumbnail {
      display: block;

      a {
        display: block;
      }

      .icon {
        width: 95px;
        height: 95px;
      }

      .background {
        aspect-ratio: 16/9;
        width: 100%;
      }
    }
  }
  .asset-profile-right {
    margin-left: 240px;
    padding: 10px;

    .table {
      > thead h3 {
        margin-top: 0;

        small {
          font-size: 60%;
        }
      }

      > tbody > tr > td {
        font-size: 13px;

        &.field {
          width: 120px;
          max-width: 120px;
          font-weight: 600;
          // elipsis
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .asset-name {
    margin-bottom: 10px;
    position: relative;

    h2 {
      line-height: 30px;
      font-size: 24px;
      margin: 0;
      padding-right: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not([class*=' text-']),
      &:not([class*='text-']) {
        color: $state-info-text;
      }
    }

    .btn-group {
      position: absolute;
      top: 0;
      right: 0;

      > .btn,
      > .btn-group > .btn {
        min-width: 50px;
      }

      > .btn-group {
        position: static;

        > .btn {
          min-width: 50px;
        }
      }
    }
  }
}

.asset-icon {
  background-color: $green-base;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: center;
  width: 50px;
  height: 50px;
  margin: 0 2px 0 0;
  display: inline-block;

  &.selectable {
    cursor: pointer;

    &:hover {
      background-color: lighten($green-base, 5%);
      background-size: 43px 43px;
    }
  }

  &.active {
    background-color: darken($green-base, 5%);
    background-size: 46px 46px;

    &:hover {
      background-color: darken($green-base, 5%);
      background-size: 46px 46px;
    }
  }
}

.asset-description {
  position: relative;
  height: 60px;
  min-height: 60px;
  overflow: hidden;

  .contraction-text {
    cursor: pointer;
    color: $state-info-text;
  }

  &.gradient {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(transparent -15px, $panel-bg);
    }

    &:after {
      content: attr(data-label);
      cursor: pointer;
      color: $state-info-text;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
    }
  }

  &.opened {
    height: auto;

    &:before,
    &:after {
      display: none;
    }
  }
}

.states-nav {
  position: absolute;
  width: 150px;

  ul {
    list-style: none;
    padding: 5px;
    margin: 0;
    background: $well-bg;

    li {
      display: block;

      a {
        display: block;
        padding: 5px 10px;
        color: $gray-dark;
        text-decoration: none;

        &:hover {
          color: $gray-darker;
        }
      }

      &.active {
        a {
          color: $state-info-text;
          font-weight: 600;
        }
      }
    }
  }
}

.states-selector {
  li {
    max-width: 200px;

    a {
      padding: 7px 15px;
    }

    &.disabled {
      a {
        cursor: default !important;
      }
    }
  }
}

.sensor-thresholds {
  height: 18px;
  position: relative;

  &.sensor-thresholds-select-option {
    padding: 8px 0;
    height: 25px;
    margin: -5px -6px;
    border-top: 1px solid $border-color;
  }

  .sensor-thresholds-bar {
    height: 8px;
    position: absolute;
    border-right: 1px solid $border-color;

    &:first-child {
      border-left: 1px solid $border-color;
    }

    &.none {
      background-color: $text-muted;
    }

    &.red {
      background-color: #dc2127;
    }

    &.yellow {
      background-color: #fbd75b;
    }

    &.green {
      background-color: #609450;
    }

    .number {
      position: absolute;
      font-size: 12px;
      margin-top: 5px;
      transform: translate(-50%, 10px);
      padding: 1px 2px;
      line-height: 12px;
      z-index: 100;

      &::before {
        content: '';
        position: absolute;
        transform: translate(0, -110%);
        left: calc(50% - 1px);
        width: 1px;
        height: 5px;
        background-color: darken($border-color, 5%);
      }

      &.even {
        transform: translate(-50%, 27px);

        &::before {
          height: 20px;
        }
      }

      &.active {
        color: $brand-success;
      }
    }
  }

  &.sensor-thresholds-flex {
    display: flex;
    flex-direction: row;

    .sensor-thresholds-bar {
      flex: 1;
      position: relative;
    }
  }

  &.sensor-thresholds-lg {
    .sensor-thresholds-bar {
      height: 12px;
    }
  }
}

.state-list {
  width: 230px;
  float: left;

  .btn {
    border-radius: 0;
  }

  .list-group {
    margin: 0;

    .list-group-item {
      border-radius: 0 !important;
    }
  }

  + div {
    width: calc(100% - 240px);
    float: right;

    > div {
      border-left: 1px solid $border-color;
    }
  }
}

.btn-sensor-state {
  height: 25px;
  color: #fff !important;
  border: none;
  text-shadow: 1px 1px #444;
  border-left: 0 !important;
  border-right: 0 !important;

  &.active {
    box-shadow: inset 0 8px 20px rgba(0, 0, 0, 0.2) !important;
  }
}

.state-message-editor {
  textarea,
  .preview {
    color: $gray-dark;
    min-height: 140px;
    max-height: 200px;
  }

  .preview {
    padding: 6px;
  }

  form {
    margin: -1px;
    position: relative;
  }

  .remaining {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 12px;
    padding: 2px 5px;
    background: $btn-default-bg;
  }

  .panel + .panel {
    border-top: 0;
  }
}

.log-status {
  display: block;
  position: absolute;
  width: 6px;
  top: 15%;
  height: 70%;

  &.log-info {
    background: $brand-info;
  }

  &.log-warning {
    background: $brand-warning;
  }

  &.log-error {
    background: $brand-danger;
  }
}

.profile {
  .property-row {
    border: 1px solid $border-color;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: block;
    position: relative;

    .property__name {
      font-weight: 600;
      font-size: 90%;
      color: $text-muted;
    }

    .property__value {
      background: transparent;
      border: none;
      font-size: 1em;
      border-radius: 0;
      display: block;
      outline: none;
    }

    .btn-clipboard {
      position: absolute;
      top: 10px;
      // right: 10px;
      padding: 5px;
      line-height: 1;
      display: none;
    }

    &:focus-within {
      border-color: $brand-primary;
      .property__name {
        color: $brand-primary;
      }
    }

    &:hover {
      .btn-clipboard {
        display: block;
      }
    }

    &.readonly {
      background-color: lighten($panel-bg, 5%);
    }
  }
}

.sensor-config {
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 10px;

  .sensor-config__left {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .bg-image-container {
      aspect-ratio: unset;
    }
  }

  .sensor-config__right {
    width: 280px;
    height: 100%;
    overflow-y: auto;

    .tab-content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .sensor-config__center {
    max-width: 600px;
    margin: auto;
    flex: 1;
    height: 100%;
    overflow-y: auto;
  }

  .sensor-config__right,
  .sensor-config__center {
    .tab-content {
      position: static;
    }

    .nav-pills {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .uib-tab {
        margin-left: 0;
        margin-right: 4px;
        float: none;
        width: 31%;
        > a {
          display: inline-block;
          padding: 4px 8px;
          line-height: 1;
          font-size: 90%;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          border: 1px solid $border-color;
        }

        &.active {
          > a {
            border-color: $btn-primary-border;
          }
        }
      }
    }
  }

  .sensor-config__heading {
    background-color: $panel-default-heading-bg;
    border-bottom: 1px solid $border-color;
  }

  .sensor-config__left,
  .sensor-config__right,
  .sensor-config__center {
    > .panel {
      height: 100%;
      margin: 0;
      overflow-y: auto;
      > .panel-heading,
      .sensor-config__heading {
        height: 30px;
        line-height: 27px;
        padding: 0 10px;
        border-top: 1px solid $border-color;

        .panel-toolbar {
          height: 28px;
          line-height: 25px;

          .btn {
            padding: 0 5px !important;
          }
        }
      }

      > .panel-body,
      .sensor-config__body {
        padding: 10px;

        legend {
          font-size: 1em;
          margin-bottom: 10px;
        }

        fieldset + fieldset {
          margin-top: 5px;
        }
      }
    }
  }

  .sensor-config__fieldset {
    border: 1px solid transparent;
    border-radius: 3px;
    legend {
      padding: 2px 5px;
    }

    &.active {
      background-color: $alert-info-bg;
      border-color: $alert-info-border;

      legend {
        background-color: $alert-info-bg;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    }
  }

  sensor-asset-state-status {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      .status-indicator-content {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .asset-profile {
    //min-height: 252px;

    .asset-profile-left {
      width: 130px;
    }

    .asset-profile-right {
      margin-left: 130px;
    }
  }
}

@media (max-width: 991px) {
  .asset-profile {
    .asset-profile-left {
      position: static;
      width: 100%;
    }

    .asset-profile-right {
      float: none;
      margin: 0;

      > .well {
        padding: 0;
      }
    }
  }
}

@media (max-width: 479px) {
  .asset-profile {
    .asset-name {
      h2 {
        padding-right: 0;
        margin-bottom: 10px;
      }

      .btn-group {
        width: 100%;
        position: static;
        display: table;

        .btn {
          display: table-cell;
          float: none;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .list .item-name {
    width: 650px;
  }
}

@media (max-width: 1100px) {
  .list .item-name {
    width: 550px;
  }
}

@media (max-width: 1000px) {
  .list .item-name {
    width: 450px;
  }
}

@media (max-width: 900px) {
  .list .item-name {
    width: 350px;
  }
}

@media (max-width: 800px) {
  .list .item-name {
    width: 350px;
  }
}

@media (max-width: 700px) {
  .list .item-name {
    width: 300px;
  }
}

@media (max-width: 650px) {
  .list .group-item .item-type-label {
    display: none;
  }
  .list .item-name {
    width: 275px;
  }
}

@media (max-width: 600px) {
  .list .group-item .item-date,
  .list .group-item .item-type-label {
    display: none;
  }
}

@media (max-width: 500px) {
  .list .item-name {
    width: 120px;
  }

  .list .group-item .item-completion {
    display: none;
  }
  .list .item-name {
    width: 150px;
  }
}

@media (max-width: 350px) {
  .list .item-name {
    width: 120px;
  }
}

@media (min-width: 450px) and (max-width: 600px) {
  .asset-scenario-content {
    .scenario-left {
      .scenario-left-fix {
        max-width: 188px;
        margin: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  .asset-scenario-content {
    .scenario-left {
      width: auto;
      float: none;
    }

    .scenario-right {
      margin-left: 0;
    }
  }
}

.floating-div {
  position: fixed;
  background-color: white;
  z-index: 10;
  width: 25%;
  left: 74%;
  border: 1px solid $border-color;
  cursor: move;
}

/* esto modifica el ancho de las tablas de detalles del asset*/
.settings-tab-body {
  width: 100%;
}
.settings-tab-field {
  width: 25%;
}

.asset-create-form {
  max-height: 800px;
  overflow-y: auto;
}

/* Estilos para el tab de agente que se encuentra en la pestaña de contextualizacion */

.agent-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;

  .agent-container__top {
    display: block;
  }

  .agent-container__bottom {
    display: flex;
    flex-direction: row;
    flex: 1 2;
    gap: 10px;

    > .panel {
      flex: 1;
      display: flex;
      flex-direction: column;

      > .panel-body {
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0;
      }
    }

    > .panel:last-child {
      flex: 2;
    }
  }
}

.camera-map {
  .button-wide {
    min-width: 100px;
  }

  .repeat-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 1%;
  }
}
