@-webkit-keyframes x-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes x-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-container {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;

  .spinner {
    border-radius: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;

    .spinner-bg {
      position: relative;
      text-indent: -9999em;
      border: 4px solid rgba(255, 255, 255, 0.2);
      border-left-color: #ffffff;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: x-spin 0.8s infinite linear;
      animation: x-spin 1s infinite linear;
      transition: border-top-color 0.1s linear, border-right-color 0.1s linear 0.1s,
        border-bottom-color 0.1s linear 0.1s;
      border-radius: 50%;
      width: 70px;
      height: 70px;

      &:after {
        border-radius: 50%;
        width: 70px;
        height: 70px;
      }
    }

    .spinner-logo {
      background-image: url('/assets/images/themes/vsaas/transparent.png');
      background-size: 100% 100%;
      position: absolute;
      opacity: 0.9;
      height: 45px;
      width: 45px;
      top: 12px;
      left: 12px;
    }

    &.spinner-ready {
      .spinner-bg {
        border-top-color: #fff;
        border-right-color: #fff;
        border-bottom-color: #fff;
      }
    }
  }

  &.small {
    .spinner-bg {
      border-width: 3px;
      width: 50px;
      height: 50px;

      &:after {
        width: 50px;
        height: 50px;
      }
    }

    .spinner {
      .spinner-logo {
        top: 10px;
        left: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }

  &.xs {
    .spinner-bg {
      border-width: 2px;
      width: 30px;
      height: 30px;

      &:after {
        width: 30px;
        height: 30px;
      }
    }

    .spinner {
      .spinner-logo {
        top: 6px;
        left: 6px;
        height: 18px;
        width: 18px;
      }
    }
  }

  .text-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    text-align: center;
    color: #fff;

    .spinner {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }

    .spinner-message {
      text-align: center;
      color: #fff;
    }
  }

  &.white {
    background: #fff;
  }

  &.light {
    background: rgba(238, 238, 238, 0.2) !important;

    .spinner-bg {
      border-left-color: #ddd !important;
    }

    &.spinner-ready {
      .spinner-bg {
        border-top-color: #ddd !important;
        border-right-color: #ddd !important;
        border-bottom-color: #ddd !important;
      }
    }
  }

  &.dark {
    background: $navbar-default-bg;

    .spinner .spinner-bg {
      border-color: #fff;
      border-left-color: rgba(0, 0, 0, 0.3);
    }

    .text-spinner {
      .spinner-message {
        color: $text-color;
      }
    }
  }

  &.black {
    background: $navbar-inverse-bg;

    .text-spinner {
      .spinner-message {
        color: $gray-light;
      }
    }
  }

  &.body-bg {
    background: $body-bg;

    .text-spinner {
      .spinner-message {
        color: $text-color;
      }
    }

    .spinner .spinner-bg {
      //border: 4px solid rgba(0, 0, 0, 0.2);
      border-color: #fff;
      border-left-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.thumbnail,
.rt-panel {
  .spinner-container {
    z-index: 20;
  }
}
