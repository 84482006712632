/* Slideshow container */

.detection-carousel {
  .slideshow-container {
    max-width: 1000px;
    //position: relative;
    margin: auto;
  }

  /* Hide the images by default */
  /*.detection-slide {
    display: none;
  }*/

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 8px 12px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 3px 0 0 3px;
    user-select: none;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.3);
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 0 3px 3px 0;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .prev.disabled,
  .next.disabled {
    background-color: rgba(0, 0, 0, 0.1) !important;
    cursor: auto;
  }

  /* Caption text */
  .text {
    color: #ffffff;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }

  /* Number text (1/3 etc) */

  .carousel-controls,
  .carousel-caption,
  .carousel-info {
    color: #ffffff;
    font-size: 12px;
    padding: 3px 6px;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .carousel-caption {
    width: 250px;
    > span,
    > small {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .carousel-controls {
    right: 0;
    max-width: 130px;
    text-align: right;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > * {
      display: inline-block;

      & + * {
        border-left: 1px solid rgba(0, 0, 0, 0.5);
      }
    }

    .btn-group {
      padding-left: 5px;
      margin-left: 5px;

      .btn-default {
        background: transparent;
        border-radius: 0;
        //border-color: rgba(0, 0, 0, 0.6);
        border: 0;
        color: #fff;
      }
    }

    .dropdown-menu {
      border-radius: 0;
      min-width: auto;
      padding: 0;
      background: rgba(0, 0, 0, 0.6);

      > li {
        > a {
          color: #fff;
          padding: 3px 10px;

          &:hover,
          &.active {
            background: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }

  .carousel-info {
    width: 100%;
    padding-left: 210px;
    padding-right: 112px;
  }

  .carousel-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 38px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;

    .carousel-controls,
    .carousel-caption,
    .carousel-info {
      background-color: transparent;
    }

    .carousel-controls {
      top: 5px;

      .dropdown-menu {
        margin-bottom: 8px;
      }
    }

    &.carousel-overlay-left {
      height: auto;
      bottom: 40px;
      width: 250px;
      z-index: 2;
    }

    .table {
      margin-bottom: 0;
      color: #fff;

      > tbody > tr > td,
      > tbody > tr > th,
      > tr > td,
      > tr > th {
        border-top-color: rgba(0, 0, 0, 0.5) !important;
      }

      > tbody > tr > th {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }

  .dot:hover {
    background-color: #717171;
  }

  /* Fading animation */
  .slide-fade {
    -webkit-animation-name: slide-fade;
    -webkit-animation-duration: 1.5s;
    animation-name: slide-fade;
    animation-duration: 1.5s;
  }

  @-webkit-keyframes slide-fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slide-fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
}
