@media (min-width: 992px) {
  #desktop-filter-menu-bar {
    display: block;
    height: 100%;
    direction: rtl;
    .navbar-nav {
      direction: ltr;
    }
  }
  .navbar-nav-vertical {
    .navbar-item {
      line-height: 16px;
      .filter-menu-title {
        height: 16px;
      }
      .nav-sub#filter-nav-sub {
        display: block;
        width: 0px;
      }
    }
  }
}

.filter-animation-slide-in {
  animation: filter-slide-in 0.5s ease-in-out 1 normal forwards;
}
.filter-animation-slide-out {
  animation: filter-slide-out 0.5s ease-in-out 1 normal forwards;
  width: 0px;
}
@keyframes filter-slide-in {
  0% {
    width: 0px;
    white-space: nowrap;
  }
  100% {
    width: 300px;
  }
}
@keyframes filter-slide-out {
  0% {
    width: 300px;
    white-space: nowrap;
  }
  100% {
    width: 0px;
    white-space: nowrap;
  }
}
