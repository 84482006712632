@font-face {
  font-family: 'svg';
  src: url('/assets/fonts/svg/svg.eot');
  src: url('/assets/fonts/svg/svg.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/svg/svg.woff') format('woff'),
    url('/assets/fonts/svg/svg.ttf') format('truetype'),
    url('/assets/fonts/svg/svg.svg#svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.svg-icon {
  display: inline-block;
  font-family: 'svg';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.svg-icon-pbi:before {
  content: '\0041';
}

.svg-icon-line-chart:before {
  content: '\0042';
}

.svg-icon-covid-19:before {
  content: '\0043';
}

.svg-icon-event-summary:before {
  content: '\0044';
}

.svg-icon-event-dashboard:before {
  content: '\0045';
}

.svg-guard:before {
  content: '\0046';
}

.svg-guard-round:before {
  content: '\0047';
}

.svg-store-opened:before {
  content: '\0048';
}

.svg-license-plate:before {
  content: '\0049';
}

.svg-forensic:before {
  content: '\004a';
}

.svg-rocks:before {
  content: '\004b';
}

.svg-container:before {
  content: '\004c';
}

/*
@font-face {
  font-family: 'sansation';
  src: url('/assets/assets/fonts/sansation/Sansation_Regular.ttf');
}*/

.glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 'FontAwesome5Free';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.glyphicon-chevron-left {
    &:before {
      content: '\f053';
    }
  }

  &.glyphicon-chevron-right {
    &:before {
      content: '\f054';
    }
  }

  &.glyphicon-chevron-up {
    &:before {
      content: '\f077';
    }
  }

  &.glyphicon-chevron-down {
    &:before {
      content: '\f078';
    }
  }
}

@font-face {
  font-family: 'sansation';
  src: url('/assets/fonts/sansation/Sansation_Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url('/assets/fonts/OpenSans/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url('/assets/fonts/OpenSans/OpenSans-Regular.ttf');
}
