input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

form.validate {
  .form-group {
    position: relative;
    margin-bottom: 25px;

    .help-block {
      @extend .nowrap;
      position: absolute;
      right: 0;
      font-size: 13px;
      margin: 0;
      display: none;
    }

    &.has-error {
      .help-block {
        display: block;
      }
    }
  }
}

.CodeMirror,
.CodeMirror-scroll {
  max-height: 365px;
}

.intl-tel-input-block {
  .iti {
    display: block;
  }
}
