.breadcrumb {
  li {
    > a,
    > span {
      display: inline-block;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: bottom;
    }
  }

  > li + li:before {
    content: '/';
    padding: 0 1px 0 5px;
  }
}

.navbar-nav {
  .breadcrumb {
    background: transparent;
    max-width: 610px;
    border: 0;
    height: $navbar-height;
    line-height: $navbar-height;
    padding: 0 5px;
  }
}

@media (max-width: 767px) {
  .navbar-nav {
    display: none;
  }
  #navbar-top-links {
    display: none;
  }
  #logout {
    color: #ef5350;
    background-color: #232930;
  }
  #profile {
    background-color: #232930;
    &:hover {
      color: #fff;
    }
  }
  #logout-vsaas {
    color: #ef5350;
    background-color: #2e363f;
  }
  #profile-vsaas {
    background-color: #2e363f;
    &:hover {
      color: #fff;
    }
  }
}
