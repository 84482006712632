.multiselect-dropdown {
  margin-top: 0;
  > .btn {
    border-radius: 0;
    color: $text-color;
    position: relative;
    border-color: $border-color;
    background: $input-bg;

    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
      background: transparent !important;
      border-color: $border-color !important;
      color: $gray-dark !important;
    }

    .fa-chevron-down {
      display: inline-block;
      width: 34px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      border-left: 1px solid $border-color;
      background-clip: padding-box;
      background: $gray-lighter;
      font-size: 11px;
      font-weight: bold;

      &:before {
        display: block;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}

.compact-select2 {
  .multiselect-dropdown {
    > .btn {
      padding: 5px 10px;
      line-height: 1.5;
      font-size: 12px;

      .fa-chevron-down {
        width: 30px;

        &:before {
          line-height: 28px;
        }
      }
    }

    .dropdown-menu {
      font-size: 12px;

      label.checkbox {
        > i:after {
          width: 15px;
          height: 15px;
          line-height: 13px;
        }
      }
    }
  }
}
