.tool-kit-gallery {
  .sector {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .tool-kit {
    display: block;
    border: 1px solid $border-color;
    padding: 2 * $spacing;
    margin-bottom: 3 * $spacing;
    background: $panel-bg;
    position: relative;
    box-shadow: 0 0 ($spacing/2) $gray-light;

    .tool-kit-thumb {
      width: 180px;
      position: absolute;
      left: 2 * $spacing;
      top: 2 * $spacing;
      bottom: 2 * $spacing;

      img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .tool-kit-content {
      padding-left: 180px + $spacing;

      .tool-kit-body {
        height: 200px;
        overflow: auto;
        border-left: 1px solid $border-color;
        padding-left: 2 * $spacing;

        p.tool-kit-description {
          overflow: hidden;
          text-align: justify;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-height: 1.2em; /* fallback */
          max-height: 1.2em; /* fallback */
        }
      }

      h5 {
        margin-top: 0;
        font-weight: bold;
        font-size: 1.1em;
      }
    }
  }
}

.tool {
  .tool-header {
    position: relative;
    margin-bottom: 4 * $spacing;
    min-height: 80px;
    .tool-icon {
      position: absolute;
      left: 0;
      text-align: center;
      .thumbnail {
        width: 80px;
        height: 80px;
        font-size: 40px;
        position: relative;

        img {
          position: absolute;
          max-width: 90%;
          max-height: 90%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .tool-name {
      padding-left: 80px + 3 * $spacing;
      position: relative;
      min-height: 80px;
      h2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .tool-body {
    background: $panel-bg;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 2 * $spacing;
  }
}

.tool-kit-gallery,
.tool {
  ul.tools-list {
    li {
      position: relative;
      padding: 5px 30px 5px 5px;

      a {
        display: block;
      }
    }

    li:hover {
      background: $gray-lightest;
    }
  }
}
