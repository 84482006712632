.highcharts-contextmenu {
  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .highcharts-menu-item {
    transition: none !important;
  }
}
