.login-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $navbar-inverse-bg;
  color: #d5d5d5;
  overflow: auto;

  hr {
    border-color: lighten($navbar-inverse-bg, 10%);
  }

  a:not(.btn) {
    color: #fff;
  }

  .login-header {
    padding: 50px 0;
    text-align: center;
    background: lighten($navbar-inverse-bg, 3%);

    .login-brand {
      background-image: url('/assets/images/themes/vsaas/logo.png');
      height: 60px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-bottom: 10px;
    }
  }

  .login-content {
    padding: 50px 0;
    max-width: 310px;
    margin: auto;
  }

  .login-form {
    .form-group {
      input,
      textarea,
      select {
        background: lighten($navbar-inverse-bg, 3%);
        outline: none;
        border-radius: 0;
        color: $gray;
        box-shadow: none !important;
        border-radius: 0px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 30px lighten($navbar-inverse-bg, 3%) inset !important;
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: $text-color !important;
          border-color: lighten($navbar-inverse-bg, 3%);
        }
      }
      input,
      select {
        &:not(.input-sm) {
          height: 40px;
        }
      }

      &:not(.has-error) {
        input,
        select,
        textarea {
          border-color: transparent;
        }
      }

      .input-group {
        background: lighten($navbar-inverse-bg, 3%);
        border-radius: 5px;

        .input-group-addon {
          padding: 12px 12px;
          background: transparent;
          height: 40px;
        }
        .input-group-btn {
          .btn {
            height: 40px;
          }
        }

        .input-group-addon + input {
          border-left: 0;
          padding-left: 0;
        }

        &.input-group-sm {
          input {
            height: 30px;
          }

          .input-group-btn .btn {
            height: 30px;
          }
        }
      }

      &:not(.has-error) {
        .input-group {
          .input-group-addon {
            border-color: transparent;
          }

          > input {
            border-color: transparent;
          }
        }
      }
    }

    .btn-primary {
      background: transparent;
      border-color: lighten($navbar-inverse-bg, 8%);
      outline: none !important;
      box-shadow: none;

      &:hover {
        background: lighten($navbar-inverse-bg, 3%);
      }

      &:active {
        border-color: lighten($navbar-inverse-bg, 10%);
        background: lighten($navbar-inverse-bg, 5%);
      }
    }
  }

  .datetime-picker-dropdown {
    color: $gray-light;

    &,
    table.uib-timepicker {
      background-color: lighten($navbar-inverse-bg, 3%);
    }

    .btn {
      &.uib-title,
      &.uib-right,
      &.uib-left {
        color: $gray-lighter;
      }
    }

    .uib-day {
      .btn-default {
        background-color: lighten($navbar-inverse-bg, 3%) !important;
        color: $gray-lighter;

        &.active {
          background-color: $navbar-inverse-bg !important;
        }
      }
    }

    .uib-increment,
    .uib-decrement {
      .btn-link {
        background-color: lighten($navbar-inverse-bg, 3%) !important;
        color: $gray-lighter;
      }
    }

    table thead th {
      border-color: lighten($navbar-inverse-bg, 20%);
    }
  }
}
