.nav-tabs {
  position: relative;
  background-color: $gray-lightest;

  > li {
    margin-bottom: -2px;

    > a {
      color: $gray-dark;
      min-width: 80px;
      text-align: center;
      border-radius: 0 !important;
      margin-right: 0;
      padding: 11px 10px !important;
      font-size: 12px;
      border-top: none !important;
      outline: none !important;

      .tab-state {
        border-radius: 2px;
        opacity: 0;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        text-align: center;
        position: absolute;
        top: 8px;
        right: 8px;
        padding: 2px 4px;
        font-size: 10px;
        color: #ffffff;

        &.error {
          opacity: 0.7;
          background-color: $brand-danger;

          &::before {
            content: 'Error';
          }
        }

        &.success {
          opacity: 0.7;
          background-color: $brand-success;

          &::before {
            content: 'Success';
          }
        }

        &.loading {
          opacity: 0.7;
          background-color: $brand-primary;

          &::before {
            content: 'Saving...';
          }
        }
      }
    }

    &.tab-title {
      a {
        cursor: default;
        font-weight: bold;
        padding: 7px 50px 8px 10px !important;
      }
    }

    &.active {
      margin-bottom: -1px;

      > a {
        color: $gray-dark;
        box-shadow: none;
        margin-top: 0 !important;
        font-weight: 700;

        &,
        &.focus,
        &:hover {
          border-top: none !important;
          background-color: $nav-tabs-active-link-hover-bg;
        }
      }

      &:first-child {
        > a {
          &,
          &.focus,
          &:hover {
            border-left: none !important;
          }
        }
      }
    }
  }

  &.nav-justified {
    width: auto;
  }
}

.tabs-bottom {
  .nav-tabs {
    border-top: 1px solid $border-color;

    > li {
      margin-bottom: 0;

      > a {
        border-bottom: none !important;
        border-top: none !important;
      }

      &.active {
        > a {
          border-bottom: none !important;
        }

        &:first-child {
          > a {
            border-left: none !important;
          }
        }
      }
    }
  }
}

.tab-content {
  position: relative;
  padding: 5px;

  .tab-pane {
    .nav-tabs {
      margin: 0 -5px;
      border-top: 1px solid $border-color;
    }

    .row > div[class*='col-'] {
      .nav-tabs {
        margin: 0;
        border-top: 0;
      }
    }
  }
}

.absolute-tabs {
  .tab-content {
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
  }

  &.tabs-bottom {
    .nav-tabs {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .tab-content {
      top: 0;
      bottom: 40px;
    }
  }
}

.tabs-inverse {
  .nav-tabs {
    background: $navbar-inverse-bg;

    > li {
      > a {
        color: $navbar-inverse-color;
        border-left: none !important;
        border-right: none !important;

        &:hover,
        &:focus {
          background: darken($navbar-inverse-bg, 4%);
        }
      }

      &.active {
        a {
          color: $gray-darker;
        }
      }
    }
  }
}

.nav-pills {
  li {
    a {
      border-radius: 4px;
      color: $state-info-text;
    }
  }
}

.tabs-left {
  .nav-tabs {
    float: left;
    border-bottom: 0;

    li {
      float: none;
      margin: 0;

      a {
        margin-right: 0;
        text-align: center;
        border: 0;
        background-color: $navbar-inverse-bg;
        padding: 10px 15px !important;
        width: 130px;
        color: $navbar-inverse-link-color;
        font-size: 12px;
        line-height: 17px;

        .fas {
          font-size: 18px;
          line-height: 25px;
        }

        &:hover {
          color: #fff;
          background: $nav-tabs-active-link-hover-bg;
        }

        * {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &.active {
        a {
          color: $gray-darker !important;
          background: $nav-tabs-active-link-hover-bg;
        }
      }
    }

    .glyphicon {
      color: #fff;
    }

    .active .glyphicon {
      color: #333;
    }
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    border: 0;
  }

  .tab-content {
    margin-left: 130px;
    padding: 0;

    .tab-pane {
      display: none;
      background-color: $panel-bg;
      overflow-y: auto;
      min-height: 350px;
      padding: 5px 10px;
    }

    .active {
      display: block;
    }
  }
}

.panel {
  .tab-content {
    padding: 10px;
  }

  .panel-heading {
    .nav-tabs {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

@media (min-width: 768px) {
  .tabs-bottom {
    .nav-tabs-justified > li > a,
    .nav-tabs.nav-justified > li > a {
      border-top: 1px solid $border-color !important;
      border-bottom: 0;
    }
  }
}
