$fa-font-path: '../fonts'; // FontAwesome dist font path
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
// @import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands';

@font-face {
  font-family: 'FontAwesome5Free';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-solid-900.eot');
  src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
    url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'),
    url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
  font-family: 'FontAwesome5Free';
  font-weight: 900;
}

@font-face {
  font-family: 'FontAwesome5FreeRegular';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-regular-400.eot');
  src: url('#{$fa-font-path}/fa-regular-400.eot?#iefix') format('embedded-opentype'),
    url('#{$fa-font-path}/fa-regular-400.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-regular-400.woff') format('woff'),
    url('#{$fa-font-path}/fa-regular-400.ttf') format('truetype'),
    url('#{$fa-font-path}/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
  font-family: 'FontAwesome5FreeRegular';
  font-weight: 400;
}

@font-face {
  font-family: 'FontAwesome5Brands';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-brands-400.eot');
  src: url('#{$fa-font-path}/fa-brands-400.eot?#iefix') format('embedded-opentype'),
    url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-brands-400.woff') format('woff'),
    url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype'),
    url('#{$fa-font-path}/fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
  font-family: 'FontAwesome5Brands';
  font-weight: 400;
}
