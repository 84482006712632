$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-base: $font-family-sans-serif;

$gray-base: #ffffff;
$gray-darker: darken($gray-base, 10%);
$gray-dark: darken($gray-base, 25%);
$gray: darken($gray-base, 50%);
$gray-light: #2e363f;
$gray-lighter: darken($gray-light, 5%);
$gray-lightest: #0e1013;

$green-base: #5e6eb4;

$brand-primary: $green-base; // #337ab7
$brand-primary-dark: darken($brand-primary, 10%);
$brand-success: #00a86b;
$brand-info: #539099;
$brand-warning: #f0ad4e;
$brand-danger: #f16b46;
$brand-secondary: #2172c3;

$state-info-text: lighten($brand-info, 25%);
$state-info-bg: #0c515c;
$input-color: $gray-dark;

$label-default-bg: lighten($gray-light, 5%);
$label-success-bg: darken($brand-success, 15%);
$label-warning-bg: darken($brand-warning, 20%);

$border-color: darken($gray-lighter, 6%);
$border-color-light: darken($gray-lighter, 3%);
$border-dark: #becdd9;

$body-bg: #2e363f;
$text-color: $gray-darker;
$text-black: $gray-lighter;
$text-selection-bg: $gray;
$text-muted: darken($text-color, 25%);
$headings-small-color: $gray;
$link-color: #fff;
$close-color: #fff;

// Button
$btn-default-color: #fff;
$btn-default-bg: $gray;
$btn-default-border: darken($btn-default-bg, 5%);

$btn-success-bg: darken($brand-success, 5%);
$btn-warning-bg: darken($brand-warning, 20%);

// Navbar
$navbar-height: 40px;
$navbar-inverse-color: #838b94;
$navbar-inverse-bg: darken($body-bg, 5%);
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-active-bg: lighten($navbar-inverse-bg, 5%);
$navbar-inverse-toggle-hover-bg: $navbar-inverse-link-active-bg;
$navbar-inverse-toggle-border-color: #555;
$navbar-inverse-link-hover-color: lighten($navbar-inverse-color, 20%);

$navbar-default-bg: darken($body-bg, 5%);
$navbar-default-border: $border-color;
$navbar-default-toggle-hover-bg: lighten($navbar-inverse-bg, 20%);
$navbar-default-toggle-border-color: lighten($navbar-inverse-bg, 20%);
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-brand-color: #fff;

$grid-gutter-width: 10px;
$container-desktop: (840px + $grid-gutter-width);
$container-large-desktop: (990px + $grid-gutter-width);

//Panel
$panel-heading-padding: 0 10px;
$panel-bg: darken($body-bg, 2%);
$panel-default-heading-bg: $gray-lighter;
$panel-footer-bg: $gray-lighter;
$panel-default-border: $border-color;
$panel-inner-border: $border-color;

// Well
$well-padding: 15px;
$well-border: $panel-default-border;
$well-bg: darken($body-bg, 2%);

// List Group
$list-group-bg: $panel-bg;
$list-group-link-color: $text-color;
$list-group-border: $border-color;
$list-group-hover-bg: lighten($green-base, 5%);

// Modal
$modal-content-border-color: transparent;
$modal-content-bg: $panel-bg;
$modal-header-border-color: $border-color;

// Input
$input-border: $border-color;
$input-border-focus: lighten($border-color, 5%);

// breadcrumb
$breadcrumb-bg: #fff;
$breadcrumb-active-color: $gray;

//== Tabs
$nav-tabs-border-color: $border-color;
$nav-tabs-link-hover-border-color: $gray-lighter;

$nav-tabs-active-link-hover-bg: $panel-default-heading-bg;
$nav-tabs-active-link-hover-color: $gray-dark;
$nav-tabs-active-link-hover-border-color: $border-color;
$nav-tabs-justified-link-border-color: $border-color;
$nav-tabs-justified-active-link-border-color: $gray-lightest;

// Dropdown
$dropdown-bg: darken($panel-bg, 3%);
$dropdown-divider-bg: darken($border-color, 3%);
$dropdown-link-hover-bg: lighten($panel-bg, 3%);
$dropdown-link-active-color: $text-color;
$dropdown-link-active-bg: $body-bg;

// Thumbnail
$thumbnail-border: $border-color;

// Table
$table-bg-accent: lighten($green-base, 6%);
$table-bg-hover: lighten($green-base, 3%);
$table-border-color: $border-color;
$table-odd-bg: darken($panel-bg, 2%);

$table-filter-color: $gray-dark;
$table-radio-bg: lighten($panel-bg, 10%);
$table-radio-border: lighten($border-color, 10%);
$table-radio-active-border: lighten($border-color, 10%);

// Popover
$popover-bg: $panel-bg;

// Pagination
$pagination-bg: $panel-bg;
$pagination-border: $border-color;
$pagination-hover-border: darken($border-color, 10%);
$pagination-disabled-bg: lighten($gray-light, 3%);
$pagination-disabled-color: darken($text-color, 5%);
$pagination-disabled-border: darken($gray-light, 5%);

$legend-border-color: darken($border-color, 3%);

$pre-bg: darken($panel-bg, 2%);

$hr-border: $border-color;

$screen-lg-min: 1300px;
@import '../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';

$input-bg: lighten($body-bg, 5%);
$input-border: $border-color;

// Mid-Small breakpoint
$screen-ms: 480px !default;
$screen-ms-min: $screen-ms;
$screen-ms-max: ($screen-sm-min - 1);

// Redefined Extra Small max value
$screen-xs-max-new: ($screen-ms-min - 1);

$spacing: floor(($grid-gutter-width / 2));
