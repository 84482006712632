.view-sidebar {
  left: 0;
  width: 360px;
  overflow: auto;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 2;
  background: $panel-bg;
  border-right: 1px solid $border-color;

  .view-sidebar-toggle-bar {
    padding: 9px 9px 0;
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    display: none;

    view-sidebar-toggle {
      margin-bottom: 0;
    }
  }

  .view-sidebar-title {
    padding: 5px 10px;
    line-height: 31px;
    height: 40px;
    font-size: 14px !important;
    font-weight: bold;
    border-bottom: 1px solid $border-color;
    background-color: $panel-default-heading-bg;

    > span {
      text-transform: uppercase;
    }
  }

  .view-sidebar-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    overflow: auto;

    .thumbnail {
      margin-bottom: 10px;
      border-radius: 0;
    }
  }

  .view-sidebar-title + .view-sidebar-content {
    top: 40px;
  }

  &.view-sidebar-right {
    left: auto;
    right: 0;
    border-right: none;
    border-left: 1px solid $border-color;
  }

  &.bordered {
    border: 1px solid $border-color;
  }

  h5 {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 5px 10px;
    margin: 10px -10px 10px;
    background: $gray-lightest;
  }

  &.collapsed {
    display: none;
  }

  .tab-content .tab-pane {
    .nav-tabs,
    .tab-content {
      margin: 0 -10px;
    }

    .nav-tabs {
      border-top: 1px solid $border-color;
    }
  }

  &.view-sidebar-sm {
    width: 300px;
  }

  &.view-sidebar-xs {
    width: 250px;
  }

  &.view-sidebar-xl {
    width: 500px;
  }

  &.view-sidebar-xxl {
    width: 40%;
  }

  &.view-sidebar-static.view-sidebar-right {
    float: right;

    .navbar-nav-vertical {
      .navbar-item {
        .nav-sub {
          right: 359px;
          left: auto;
          z-index: 21;
        }
      }
    }

    &.view-sidebar-sm {
      .navbar-nav-vertical {
        .navbar-item {
          .nav-sub {
            right: 299px;
          }
        }
      }
    }

    &.view-sidebar-xs {
      width: 250px;

      .navbar-nav-vertical {
        .navbar-item {
          .nav-sub {
            right: 249px;
          }
        }
      }
    }

    &.view-sidebar-xl {
      .navbar-nav-vertical {
        .navbar-item {
          .nav-sub {
            right: 499px;
          }
        }
      }
    }

    &.view-sidebar-xxl {
      .navbar-nav-vertical {
        .navbar-item {
          .nav-sub {
            right: calc(40% - 1px);
          }
        }
      }
    }
  }

  &.hidden-content {
    width: 38px !important;
    overflow-x: hidden;

    + div {
      left: 38px !important;
    }
  }

  &.view-sidebar-static {
    position: static;
    height: 100%;
  }

  .navbar-nav-vertical {
    padding: 0;
    float: none;

    .navbar-item {
      float: none;
      border-bottom: 1px solid $border-color;

      .nav-sub {
        display: none;
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 299px;
        width: 300px;
        background: $panel-bg;
        overflow-y: auto;
        z-index: 2;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;

        h3 {
          margin: 0;
          font-size: 14px;
          font-weight: bold;
        }

        label.checkbox {
          font-weight: normal;
        }

        .form-group {
          position: relative;

          .datetime-picker-dropdown {
            font-size: 12px;
            min-width: 200px !important;

            > li {
              padding: 0 0 5px !important;
            }

            table td > .btn,
            .btn-group .btn {
              padding: 4px 8px;
              font-size: 11px;
              border-radius: 0;
            }
          }
        }
      }

      > a {
        display: block;
        text-decoration: none;
        padding: 15px 20px;
        cursor: default;

        &:hover {
          background: $body-bg;
        }

        > .navbar-item-title {
          font-size: 16px;
        }

        > .navbar-item-desc {
          height: 20px;
          font-size: 11px;
        }
      }

      &:hover:not(.disabled) {
        .nav-sub {
          display: block;
        }
      }
    }
  }

  label {
    font-weight: normal;
  }
}

.view-sidebar + div {
  padding: 10px;
  overflow-x: hidden;
}

view-sidebar-toggle {
  display: block;
  padding: 5px 10px 4px;
  background: $panel-default-heading-bg;
  border-bottom: 1px solid $border-color;
  text-align: right;
  margin: -10px -10px 10px;
}

@media (min-width: 992px) {
  .view-sidebar + div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 360px;
    overflow-y: scroll;
  }

  .view-sidebar-xs + div {
    left: 250px;
  }

  .view-sidebar-sm + div {
    left: 300px;
  }

  .view-sidebar-xl + div {
    left: 500px;
  }

  .view-sidebar-xxl + div {
    left: 40%;
  }

  .view-sidebar.collapse + div {
    left: 0;
  }

  .view-sidebar.view-sidebar-right + div {
    left: 0;
    right: 360px;
  }

  .view-sidebar.view-sidebar-right.view-sidebar-xs + div {
    left: 0;
    right: 250px;
  }

  .view-sidebar.view-sidebar-right.view-sidebar-sm + div {
    left: 0;
    right: 300px;
  }

  .view-sidebar.view-sidebar-right.view-sidebar-xl + div {
    left: 0;
    right: 500px;
  }

  .view-sidebar.view-sidebar-right.view-sidebar-xxl + div {
    left: 0;
    right: 40%;
  }

  /*  .view-sidebar.view-sidebar-right + div {
      left: 0;
      right: 40%;
    }*/

  .view-sidebar.collapsed + div {
    left: 0;
    right: 0;
  }
}

@media (max-width: 479px) {
  .view-sidebar {
    width: 100%;

    .view-sidebar-toggle-bar {
      display: block;
    }

    .view-sidebar-content {
      top: 74px;
    }

    .view-sidebar-title + .view-sidebar-content {
      top: 111px;
    }
  }
}
