.table {
  font-size: 13px;

  &.table-borderless {
    td, th {
      border: none
    }
  }

  td {
    border-color: $border-color-light !important;
    position: relative;
  }

  tr {
    &.separator {
      td {
        padding: 0;
        height: 10px;
      }
    }

    &.bordered {
      td, th {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
      }
    }
  }

  thead {
    &.text-center {
      th, td {
        text-align: center;
      }
    }
  }

  &.table-condensed {
    .options {
      position: relative;

      .btn-group {
        position: absolute;
        top: 3px
      }
    }
  }

  &.table-expanded {
    font-size: $font-size-base*1.05;

    thead tr th, thead tr td, tbody tr th, tbody tr td {
      padding: $table-cell-padding*1.9 $table-cell-padding;
    }

    td {
      > .fas {
        font-size: 20px;
        line-height: 18px;
        vertical-align: bottom;

        &:after {
          vertical-align: text-bottom;
        }
      }
    }
  }

  &.table-compact {
    font-size: $font-size-base*0.85;

    thead tr th, thead tr td, tbody tr th, tbody tr td {
      padding: 3px;
    }
  }

  td.relative, th.relative {
    position: relative;
    padding: 0;

    .btn {
      position: absolute;
      top: 6px;
      border-radius: 2px;
    }
  }

  .table-buttons {
    text-align: center;
    position: absolute;
    top: 6px;

    .btn + .btn {
      vertical-align: bottom;
    }
  }

  &.text-center {
    th {
      text-align: center;
    }
  }
}

.sticky-table {
  .table {
    border-collapse: separate;
    border-right: 0;
    border-top: 0;

    td {
      border-top: 0;
      border-left: 0;
    }

    th {
      position: sticky;
      border-left: 0;
      top: 0;
      background: $gray-lightest;
      z-index: 2;
    }

    > thead:first-child > tr > th {
      border-top: 1px solid $table-border-color;
    }
  }
}

.responsive-table {
  @media screen and (max-width: 991px) {
    table {
      border: 0;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    table tr {
      display: block;
      margin-bottom: .625em;
    }
    table td {
      display: block;
      text-align: left;
      padding-left: 125px !important;
      position: relative;
    }

    table td.field {
      display: none;
    }

    table td:before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      width: 120px;
      position: absolute;
      left: 0;
      font-weight: bold;
    }

    table td.block {
      padding-left: 0 !important;

      &:before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        display: block;
        position: static;
      }
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
}
