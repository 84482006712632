.clear-tabs {
  padding: 0;
  margin: 0 0 5px 0;
  > li {
    list-style: none;
    display: inline-block;
    padding: 0 10px;

    a {
      text-decoration: none;
      color: $text-color;
      font-size: 15px;
    }

    &.active {
      a {
        font-weight: bold;
        color: $state-info-text
      }
    }
  }

  .nav-tabs {
    background: none !important;
    border: none !important;
    > li {
      border: none !important;
      margin: 0 !important;
      padding: 0 10px;
      > a {
        padding: 0 !important;
        border: none !important;
        background: none !important;
        min-width: 0 !important;

        &:hover {
          background: none !important;
          font-weight: bold;
        }
      }

      &.active {
        a {
          font-weight: bold;
          color: $state-info-text
        }
      }
    }
  }
}
