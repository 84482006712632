.clipboard {
  .btn-clipboard {
    display: none;
    float: right;
    cursor: copy;
  }

  &:hover {
    .btn-clipboard {
      display: initial;
    }
  }
}

.table {
  .clipboard {
    position: relative;

    .btn-clipboard {
      position: absolute;
      //right: 3px;
      float: right;
      margin-right: 5px;
      top: 2px;
    }
  }

  &.table-condensed {
    .clipboard {
      .btn-clipboard {
        //right: 5px;
        top: 3px;
      }
    }
  }

  &.table-compact {
    .clipboard {
      .btn-clipboard {
        right: 3px;
        top: 1px;
        padding: 3px 4px;
        font-size: 11px;
        line-height: 0;
      }
    }
  }
}
