.data-display-container {
  display: flex;
  padding: 0;
  height: 100%;
  .data-display-sidebar {
    overflow-y: scroll;
    background-color: $panel-bg;
    border: 1px solid $border-color;
    .data-display-sidebar-title {
      padding: 5px 10px;
      border-bottom: 1px solid $border-color;
      span {
        text-transform: uppercase;
        line-height: 31px;
        font-size: 14px;
        font-weight: 700;
      }
    }
    .data-display-sidebar-body {
      padding: 10px;
    }
  }
  .data-display-detection-object-container {
    padding: 10px;

    img {
      overflow-x: hidden;
    }
  }
}

@media (min-width: 992px) {
  .data-display-container {
    flex-direction: row-reverse;
    justify-content: space-between;
    .data-display-sidebar {
      width: 300px;
    }
  }
  .data-display-detection-object-container {
    margin-left: 10px;
    width: calc(100% - 320px);
  }
}
@media (max-width: 991px) {
  .data-display-container {
    flex-direction: column-reverse;
    align-items: center;
    .data-display-sidebar {
      width: calc(100% - 20px);
      .data-display-sidebar-body {
        width: 100%;
        detections-cropper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          detection-crop-element {
            margin: 5px;
          }
        }
      }
    }
  }
  .data-display-detection-object-container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .data-display-detection-object-container {
    .floating-container.floating-div {
      // This expression sets the maximum width of the container to the minimum between:
      // - 70% of the screen width calculated as a 16/9 ratio of the screen height.
      // - 95% of the screen width obtained directly.
      // As the maximum height of the container is a 9/16 ratio of its maximum width, this
      // also bounds the maximum height of the container by 70% of the screen height.
      max-width: calc(min(70vh * 16/ 9, 80vw));
      // This expression centers the container horizontally by setting the left value to
      // the difference between half of the screen width and half of the maximum width of the container.
      left: calc(50vw - min(70vh * 16/ 9, 80vw) / 2);
      // This expression centers the container vertically analogously.
      // 10vh is an extra margin which may be replaced according to taste.
      top: calc(50vh - min(70vh * 16/ 9, 80vw) * (9 / 16) / 2 - 10vh);
      width: 100%;
    }
  }
}
@media (max-width: 767px) {
  .data-display-detection-object-container {
    .floating-container.floating-div {
      // This expression sets the maximum width of the container to the minimum between:
      // - 70% of the screen width calculated as a 16/9 ratio of the screen height.
      // - 95% of the screen width obtained directly.
      // As the maximum height of the container is a 9/16 ratio of its maximum width, this
      // also bounds the maximum height of the container by 70% of the screen height.
      max-width: calc(min(70vh * 16/ 9, 95vw));
      // This expression centers the container horizontally by setting the left value to
      // the difference between half of the screen width and half of the maximum width of the container.
      left: calc(50vw - min(70vh * 16/ 9, 95vw) / 2);
      // This expression centers the container vertically analogously.
      // 10vh is an extra margin which may be replaced according to taste.
      top: calc(50vh - min(70vh * 16/ 9, 95vw) * (9 / 16) / 2 - 10vh);
      width: 100%;
    }
  }
}
