dashboard-row-section {
  display: block;
  border: 1px solid $border-color;
  background-color: #ffffff;

  + dashboard-row-section {
    border-top: 0;
  }

  fieldset {
    legend {
      font-size: 13px;
      text-transform: uppercase;
      color: darken($brand-info, 5%);
      font-weight: bold;
      padding: 3px 10px;
      background: $body-bg;
      margin-bottom: 0;
      width: 100%;
      width: -moz-available;
      width: -webkit-fill-available;
      width: fill-available;
    }
  }

  > section > div {
    padding: 5px;
  }
}
