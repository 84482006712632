.tooltip {
  .tooltip-inner {
    max-width: 290px;
    font-size: 13px;
    background-color: darken($navbar-inverse-bg, 5%);
    opacity: 1;
  }

  &.tooltip.bottom .tooltip-arrow {
    border-bottom-color: darken($navbar-inverse-bg, 5%);
  }
}
